import { FeaturesReady } from '@growthbook/growthbook-react';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';

import MenuBuilderCard from '@/components/menu/MenuBuilderCard/MenuBuilderCard';
import type { ProductModelType } from '@/models/api';
import type { OrderPlan, Position } from '@/models/cart/order-plan';
import { useStore } from '@/models/root-store';
import { type Action, type Mode } from '@/utils/constants';

import PreFilledCartCard from '../MenuBuilderCard/PreFillCartCard';
import UpsellCard from '../MenuBuilderCard/UpsellCard';

export interface IProductGrid {
  products: ProductModelType[] | undefined;
  orderPlan: OrderPlan;
  action?: Action;
  handleSwap: (product: ProductModelType, x: number, y: number) => void;
  selectedItemPosition?: Position;
  planRoute: string;
  mode?: Mode;
  planIdToView?: string;
  filterNavHeight?: number;
  subCategoryName?: string;
  preFillProps?: any;
  upsellView?: boolean;
  eventSource?: string;
}

export const ProductGrid: React.FC<IProductGrid> = observer(
  ({
    products,
    orderPlan,
    action,
    handleSwap,
    selectedItemPosition,
    planRoute,
    mode,
    planIdToView,
    filterNavHeight,
    subCategoryName,
    preFillProps,
    upsellView = false,
    eventSource,
  }) => {
    const {
      productStore: {
        filters: { isFiltered },
      },
    } = useStore();
    const showPreFillCard =
      !isFiltered && subCategoryName === 'High Protein' && !action && !mode;
    let shownProducts: (ProductModelType | null)[] = products ?? [];
    if (showPreFillCard) {
      shownProducts = [
        ...shownProducts.slice(0, 2),
        null,
        ...shownProducts.slice(2),
      ];
    }

    const ProductCard = upsellView ? UpsellCard : MenuBuilderCard;

    return (
      <div
        className={
          upsellView
            ? 'grid grid-cols-2 gap-4 sm:grid-cols-1 lg:gap-6 xl:grid-cols-1 xl:gap-8'
            : 'grid grid-cols-2 gap-4 overflow-auto sm:grid-cols-3 lg:gap-6 xl:grid-cols-4 xl:gap-8'
        }
      >
        {shownProducts?.map((product, index) => (
          <Fragment key={product?.sku ?? `prefill-card-${index}`}>
            {product === null ? (
              <FeaturesReady timeout={2000}>
                <PreFilledCartCard {...preFillProps} />
              </FeaturesReady>
            ) : (
              <ProductCard
                key={product.sku}
                product={product}
                orderPlan={orderPlan}
                action={action}
                handleSwap={handleSwap}
                selectedItemPosition={selectedItemPosition}
                planRoute={planRoute}
                mode={mode}
                planIdToView={planIdToView}
                filterNavHeight={filterNavHeight}
                location={eventSource}
              />
            )}
          </Fragment>
        ))}
      </div>
    );
  },
);

import React from 'react';

import Button from '@/components/common/Button/Button';
import CopyIcon from '@/components/icons/CopyIcon';
import type { ColorType } from '@/utils/constants';
import { ToastEnum } from '@/utils/constants';
import { showToast } from '@/utils/helpers';

import MenuStructuredText from '../MenuStructuredText/MenuStructuredText';

export type TitleType = 'code' | 'cart' | 'checkout';

export enum TileKey {
  Menu = 'menu',
  Cart = 'cart',
  Checkout = 'checkout',
}

export interface IITile {
  titleType: TitleType;
  title: string;
  code: string;
  description: any;
  borderColor?: ColorType;
  backgroundColor?: ColorType;
  textColor?: ColorType;
  textHighlightColor?: ColorType;
  onClick?: () => void;
}

const handleCopyCode = (code: string) => {
  navigator.clipboard.writeText(code);
  showToast('Code copied!', ToastEnum.SUCCESS, 'menu-tile-code');
};

const TileComponent: React.FC<IITile> = ({
  title,
  description,
  code,
  titleType,
  backgroundColor,
  borderColor,
  textColor,
  textHighlightColor,
}) => {
  const colorClasses = {
    background: backgroundColor?.hex || '#848484',
    border: borderColor?.hex || '#000000',
    text: textColor?.hex || '#000000',
    textHighlight: textHighlightColor?.hex || '#008A22',
  };
  const renderTileContent = () => {
    switch (titleType) {
      case 'code':
        return (
          <div
            className={`flex w-full items-center justify-center space-x-4 rounded-md border-2 border-dashed p-4 md:w-full md:flex-row md:items-center md:justify-center md:px-8 md:py-4`}
            style={{
              background: colorClasses.background,
              borderColor: colorClasses.border,
            }}
          >
            {title && (
              <p className="mb-2 text-xl font-semibold md:mb-0 md:text-base">
                {title}
              </p>
            )}
            <div className="mr-4 md:mb-0">
              <MenuStructuredText
                content={description}
                theme="menuPromo"
                textHighlightColor={colorClasses.textHighlight}
              />
            </div>
            <div className="shrink-0 md:ml-8">
              <Button
                className="py-1 font-interSemibold text-xs md:text-base"
                theme="tertiary"
                onClick={() => handleCopyCode(code)}
                type={'button'}
              >
                Copy code
                <div className="ml-1 flex items-center">
                  <CopyIcon fill="#00000" />
                </div>
              </Button>
            </div>
          </div>
        );
      case 'cart':
        return (
          <div
            className={`flex max-h-[33px] w-full items-center justify-center space-x-4 px-3 py-4`}
            style={{
              background: colorClasses.background,
            }}
          >
            <div className="md:mb-0">
              <MenuStructuredText
                content={description}
                theme="cartPromo"
                textColor={colorClasses.text}
              />
            </div>
            <div className="shrink-0 md:ml-8">
              <Button
                className="max-h-[17px] py-1 font-interSemibold text-xs"
                theme="tertiary"
                onClick={() => handleCopyCode(code)}
                type={'button'}
              >
                <p className={`font-inter text-xs`}>Code:&nbsp;</p>
                {code}
              </Button>
            </div>
          </div>
        );
      case 'checkout':
        return (
          <div
            className={`flex max-h-[42px] w-full items-center justify-center lg:justify-around xl:justify-center xl:space-x-2 xl:px-2 py-4`}
            style={{
              background: colorClasses.background,
            }}
          >
            <div className="md:mb-0">
              <MenuStructuredText
                content={description}
                theme="checkoutPromo"
                textColor={colorClasses.text}
              />
            </div>
            <div className="shrink-0">
              <Button
                className="max-h-[17px] py-1 font-interSemibold text-sm lg:px-1 xl:px-2"
                theme="tertiary"
                onClick={() => handleCopyCode(code)}
                type={'button'}
              >
                <p className={`font-inter text-sm`}>Code:&nbsp;</p>
                {code}
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return <div className="rounded-xl bg-off-white">{renderTileContent()}</div>;
};

export const Tile = React.memo(TileComponent);

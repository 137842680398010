import Link from 'next/link';
import React from 'react';

import { getStylingFromMarks } from '@/utils/helpers';

interface MenuStructuredTextProps {
  content: any;
  theme?: 'code' | 'cart' | 'cartPromo' | 'menuPromo' | 'checkoutPromo';
  textColor?: string;
  textHighlightColor?: string;
}

type Themes = {
  code: string;
  cart: string;
  cartPromo: string;
  menuPromo: string;
  checkoutPromo: string;
};

const themes: Themes = {
  code: 'mb-4 font-interMedium text-xs md:mb-0 md:text-base',
  cart: 'mb-4 font-interMedium text-xs md:mb-0 md:text-base xl:text-lg',
  cartPromo: 'font-inter text-xs md:mb-0',
  menuPromo: 'font-interMedium text-sm md:mb-0 md:text-base',
  checkoutPromo: 'font-interMedium text-sm md:mb-0',
};

const MenuStructuredText: React.FC<MenuStructuredTextProps> = ({
  content,
  theme = 'code',
  textHighlightColor,
}: MenuStructuredTextProps) => {
  const themeClass = themes[theme];

  const renderChildren = (children: any) => {
    return children.map((child: any, index: number) => {
      if (child.type === 'span') {
        const { classNames, inlineStyle } = getStylingFromMarks(
          child.marks,
          textHighlightColor,
        );
        return (
          <span
            className={classNames.join(' ')}
            key={index}
            style={inlineStyle}
          >
            {child.value}
          </span>
        );
      }
      // Handle other child types here (e.g., links, images)
      if (child.type === 'link') {
        const linkUrl = child.url.replace(
          /https?:\/\/(www\.)?my(muscle|code)chef\.com/,
          '',
        );
        const innerChild = child?.children?.[0];
        let classNames;
        let inlineStyle;
        if (innerChild) {
          const styling = getStylingFromMarks(
            innerChild.marks,
            textHighlightColor,
          );
          classNames = styling.classNames;
          inlineStyle = styling.inlineStyle;
        }
        const isInternal = linkUrl.startsWith('/');
        const linkTarget = child?.meta?.[0]?.value ?? '_self';
        const linkText = child?.children[0]?.value;
        return (
          <span
            key={index}
            className={classNames?.join(' ') ?? ''}
            style={inlineStyle ?? {}}
          >
            {isInternal ? (
              <Link href={linkUrl} rel="noopener noreferrer" legacyBehavior>
                <a target={linkTarget}>{linkText}</a>
              </Link>
            ) : (
              <a href={linkUrl} target="_blank" rel="noreferrer">
                {linkText}
              </a>
            )}
          </span>
        );
      }
      return null;
    });
  };

  return (
    <div>
      {content.value.document.children.map((block: any, index: number) => {
        if (block.type === 'paragraph') {
          return (
            <p id="mb-tile" className={themeClass} key={index}>
              {renderChildren(block.children)}
            </p>
          );
        }
        return null;
      })}
    </div>
  );
};

export default MenuStructuredText;

import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import type { MenuHierarchyRecordModelType } from '@/models/api';
import { useStore } from '@/models/root-store';

import MobileCategory from '../MobileCategory/MobileCategory';

interface MegaNavMobileProps {
  categories: Array<MenuHierarchyRecordModelType>;
  selectedCategory: MenuHierarchyRecordModelType | undefined;
  setSelectedCategory: (category: MenuHierarchyRecordModelType) => void;
  setParentCategory: (
    category: MenuHierarchyRecordModelType | undefined,
  ) => void;
  onClick: () => void;
}

const MobileCategories = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  setParentCategory,
  onClick,
}: MegaNavMobileProps) => {
  const router = useRouter();
  const { productStore } = useStore();
  const { filters } = productStore;

  const [selectedCategoryLink, setSelectedCategoryLink] = useState(
    selectedCategory?.slug,
  );

  const goToCategory = (
    category: MenuHierarchyRecordModelType,
    parentCategory: MenuHierarchyRecordModelType | undefined,
  ) => {
    if (category.childrenCategories && category.childrenCategories.length > 0) {
      setSelectedCategory(category);
      setParentCategory(parentCategory);
    } else {
      onClick();
      filters.clearFilters();
      router.push(`${selectedCategoryLink}/${category.slug}`);
    }
  };

  const viewAll = () => {
    onClick();
    filters.clearFilters();
    router.push(`${selectedCategoryLink}`);
  };

  useEffect(() => {
    const link = `/menu${
      selectedCategory ? `/${selectedCategory.hierarchySlug}` : ''
    }`;
    setSelectedCategoryLink(link);
  }, [selectedCategory]);

  return (
    <>
      {/* Products Categories */}
      {!selectedCategory && (
        <ul className="flex flex-col pb-1 font-interSemibold text-xl">
          {categories.map((category: MenuHierarchyRecordModelType) => {
            return (
              <MobileCategory
                key={category.slug}
                category={category}
                goToCategory={goToCategory}
              />
            );
          })}
        </ul>
      )}

      {/* Products Sub Categories */}
      {selectedCategory && selectedCategory?.childrenCategories && (
        <ul className="mb-8 flex flex-col font-interMedium text-lg">
          <li
            className="h-[44px] pt-2 font-interSemibold"
            onClick={() => viewAll()}
          >
            <span className="leading-7">View all {selectedCategory.name}</span>
          </li>
          {selectedCategory?.childrenCategories?.map(
            (category: MenuHierarchyRecordModelType) => {
              return (
                <MobileCategory
                  key={category.slug}
                  category={category}
                  parentCategory={selectedCategory}
                  goToCategory={goToCategory}
                />
              );
            },
          )}
        </ul>
      )}
    </>
  );
};

export default MobileCategories;

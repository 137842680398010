import { observer } from 'mobx-react-lite';
import React from 'react';

import Button from '@/components/common/Button/Button';

export type UpsellMobileFooterTypes = {
  onRequestClose: () => void;
};

const UpsellMobileFooter: React.FC<UpsellMobileFooterTypes> = observer(
  ({ onRequestClose }: UpsellMobileFooterTypes) => {
    return (
      <div
        className="sticky bottom-0 left-0 z-[9999999] mt-0 flex w-full flex-col justify-between bg-white"
        style={{
          boxShadow:
            '0px 32px 64px rgba(0, 0, 0, 0.1876), 0px 2px 21px rgba(0, 0, 0, 0.1474)',
        }}
      >
        <div className={`px-6 py-4`}>
          <div className={`flex h-10 justify-center gap-5`}>
            <Button
              className="w-full"
              type="button"
              theme="mealPlan"
              onClick={() => onRequestClose()}
            >
              <span className={`w-full pr-1 font-interMedium text-base`}>
                Continue to Checkout
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

export default UpsellMobileFooter;

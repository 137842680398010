import { useRouter } from 'next/router';
import { useEffect } from 'react';

type Handler = (event: MouseEvent) => void;
const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: Handler,
  ignorePathIncludes?: string,
) => {
  const router = useRouter();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ignorePathIncludes) {
        const currentPath = router.asPath;
        if (currentPath.includes(ignorePathIncludes)) {
          return;
        }
      }

      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, ignorePathIncludes]);
};

export default useClickOutside;

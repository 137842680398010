import Image from 'next/legacy/image';
import Link from 'next/link';
import React from 'react';

import data from '@/mock-data/footer-data.json';

import DropdownLink from '../Dropdown/DropdownLink';

export default function Footer() {
  return (
    <>
      <footer className="bg-off-lite font-interMedium text-xs text-black">
        <div className="flex flex-col px-6 pt-8 lg:flex-row lg:justify-between lg:px-16 lg:pb-[128px] lg:pt-[72px] xl:mx-auto xl:max-w-[1248px] xl:px-0">
          <div className="mb-14 flex flex-col items-center lg:order-last lg:mb-0 lg:block">
            <div className="pb-6 font-interMedium text-base leading-none">
              <h5>Download Our App</h5>
            </div>
            <ul className="flex h-10 gap-6 lg:block lg:h-auto">
              <li className="cursor-pointer lg:mb-1">
                <Link href="https://mymusclechef.app.link/gdP0h3w5dHb">
                  <Image
                    src="/assets/images/svg/mymc-appstore.svg"
                    alt="app-store"
                    width={127}
                    height={42}
                    className="rounded-l-lg"
                  />
                </Link>
              </li>
              <li className="cursor-pointer lg:hidden">
                <Link href="https://mymusclechef.app.link/nFt100M5dHb">
                  <Image
                    src="/assets/images/svg/mymc-googleplay.svg"
                    alt="play-store"
                    width={136}
                    height={42}
                    className="rounded-l-lg"
                  />
                </Link>
              </li>
              <li className="hidden cursor-pointer lg:block">
                <Link href="https://mymusclechef.app.link/nFt100M5dHb">
                  <Image
                    src="/assets/images/svg/mymc-googleplay.svg"
                    alt="play-store"
                    width={127}
                    height={42}
                    className="rounded-l-lg"
                  />
                </Link>
              </li>
            </ul>
          </div>
          {data.data.footer.footerSections.map((l: any) => (
            <div key={l.footerSection[0].id}>
              {l.footerSection && l.footerSection.length > 0
                ? l.footerSection.map((item: any) => {
                    return <DropdownLink item={item} key={item.id} />;
                  })
                : null}
            </div>
          ))}
          <div className="mt-8 lg:-order-1 lg:mt-0">
            <div className="mb-8 flex h-[39px] justify-center lg:mb-6 lg:block">
              <Image
                style={{ color: 'white' }}
                src={data.data.footer.brandLogo.url}
                alt={data.data.footer.brandLogo.alt}
                width={77}
                height={39}
              />
            </div>
            <ul className="hidden lg:block">
              <li className="pb-[9.6px] leading-none">ABN 92 162 190 070</li>
              <li className="pb-[9.6px] leading-none">1 NORRIE STREET</li>
              <li className="pb-[9.6px] leading-none">YENNORA NSW 2161</li>
              <li className="pb-[9.6px] leading-none">1300 364 993</li>
            </ul>
          </div>
        </div>
        <div className="mt-[18px] flex flex-col items-center lg:mt-0 lg:flex-row lg:justify-between lg:px-16 lg:py-[19px] xl:mx-auto xl:max-w-[1248px] xl:px-0">
          <div className="lg:hidden">
            <Image
              src={'/assets/images/svg/payment-options.svg'}
              alt="payment options"
              width={339}
              height={21}
            />
          </div>
          <div className="hidden h-[18px] lg:block">
            <Image
              src={'/assets/images/svg/payment-options.svg'}
              alt="payment options"
              width={289}
              height={18}
            />
          </div>

          <p className="mb-6 mt-7 font-interMedium text-xxs leading-4 text-gray-400 lg:my-0 lg:font-inter lg:leading-5">
            Copyright 2013 - {new Date().getFullYear()} My Muscle Chef Pty Ltd.
            All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}

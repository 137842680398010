import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import type { NavbarLinkRecordModelType } from '@/models/api';

interface MobileNavLinkProps {
  navLink: NavbarLinkRecordModelType;
  setSelectedLink?: (link: NavbarLinkRecordModelType) => void;
  selectedLink?: NavbarLinkRecordModelType;
  onClick: () => void;
}

const MobileNavLink = ({
  navLink,
  setSelectedLink,
  onClick,
}: MobileNavLinkProps) => {
  const subLinks = navLink.subLinks?.length
    ? navLink.subLinks
    : navLink.extraLinks;
  const showSubLinks = () => {
    if (subLinks && subLinks.length > 0 && setSelectedLink) {
      setSelectedLink(navLink);
    } else {
      onClick();
    }
  };

  if (subLinks && subLinks.length > 0) {
    return (
      <li
        className="flex h-[44px] w-full items-center justify-between leading-7 text-black"
        onClick={showSubLinks}
      >
        <span
          style={{ backgroundColor: navLink.backgroundColour?.hex }}
          className="flex flex-row items-center justify-center rounded-full px-3 py-1"
        >
          {navLink.text}
          {navLink.icon && (
            <Image
              src={navLink.icon.url}
              width={12}
              height={12}
              alt={navLink.text || ''}
              className="ml-1"
            />
          )}
        </span>

        <Image
          width={30}
          height={30}
          src="https://www.datocms-assets.com/81001/1701308267-arrow-down.svg"
          alt=""
        />
      </li>
    );
  }

  return (
    <Link href={navLink.relativePathLink || navLink.externalUrl || '/'}>
      <li
        className="flex h-[44px] w-full items-center justify-between leading-7 text-black"
        onClick={showSubLinks}
      >
        <span
          style={{ backgroundColor: navLink.backgroundColour?.hex }}
          className="flex flex-row items-center justify-center rounded-full px-3 py-1"
        >
          {navLink.text}
          {navLink.icon && (
            <Image
              src={navLink.icon.url}
              width={12}
              height={12}
              alt={navLink.text || ''}
              className="ml-1"
            />
          )}
        </span>
      </li>
    </Link>
  );
};

export default MobileNavLink;

import { observer } from 'mobx-react-lite';
import Image from 'next/legacy/image';
import React from 'react';

import AuthModal from '@/components/auth/AuthModal/AuthModal';
import Button from '@/components/common/Button/Button';
import DiscountProgressBar from '@/components/common/DiscountProgressBar/DiscountProgressBar';
import type { TitleType } from '@/components/menu/Tile/Tile';
import { Tile, TileKey } from '@/components/menu/Tile/Tile';
import { useAuthModal } from '@/hooks/useAuthModal';
import type { Banner } from '@/models/cart/banner';
import { useStore } from '@/models/root-store';
import { Mode } from '@/utils/constants';

export type CartFooterTypes = {
  total: number;
  MOV: number;
  cartMode?: Mode | undefined;
  checkoutOrSave: () => void;
  loading?: boolean;
  variation?: boolean;
};

const CartFooter: React.FC<CartFooterTypes> = observer(
  ({
    total,
    MOV,
    cartMode,
    checkoutOrSave,
    loading = false,
    variation,
  }: CartFooterTypes) => {
    const {
      cartStore: { banner },
      generalStore: { SMOV, freeShippingThreshold },
    } = useStore();
    const [isAuthModalOpen, setIsAuthModalOpen] = useAuthModal();
    const justify = variation ? 'justify-between' : 'justify-end';
    const width = variation ? 'w-32' : '';
    const filteredTile = (banner as Banner)?.getBannerByKey(TileKey.Cart);
    const isSubscription = cartMode === Mode.EDIT_SUBSCRIPTION;
    const minValue = isSubscription ? SMOV : MOV;
    const renderText = (mode: Mode | undefined) => {
      switch (mode) {
        case Mode.EDIT_SUBSCRIPTION:
          return 'Save Changes';
        default:
          return 'Checkout';
      }
    };

    const renderMinOrderValueMessage = (
      totalValue: number,
      minVal: number,
      isSub: boolean,
      freeShippingT?: number,
    ) => {
      if (isSub) {
        if (totalValue < SMOV) {
          return <span>Min. subscription value is ${SMOV}</span>;
        }
        if (freeShippingT && totalValue < freeShippingT) {
          return <span>Free shipping for orders over ${freeShippingT}</span>;
        }
        return null;
      }
      if (totalValue < minVal) {
        return <span>Min. order value ${minVal}</span>;
      }
      return <span>Shipping & promotions applied at checkout.</span>;
    };

    return (
      <div
        className="sticky bottom-0 left-0 z-[9999999] mt-0 flex w-full flex-col justify-between bg-white"
        style={{
          boxShadow:
            '0px 32px 64px rgba(0, 0, 0, 0.1876), 0px 2px 21px rgba(0, 0, 0, 0.1474)',
        }}
      >
        {filteredTile && (
          <Tile
            code={banner.code || ''}
            title={filteredTile?.title || ''}
            description={filteredTile?.description || ''}
            titleType={(filteredTile?.titleType || 'cart') as TitleType}
            backgroundColor={filteredTile?.backgroundColor}
          />
        )}
        <div className={`px-6 py-4`}>
          <DiscountProgressBar view="small" />
          <div className="mt-3 flex justify-between">
            {!variation && (
              <div>
                <p className="mb-1.5 font-interMedium text-base leading-none tracking-wide lg:text-2xl">
                  ${total.toFixed(2)}
                </p>
                <p className="mb-1.5 font-interMedium text-xs leading-4 text-grey-dark md:max-w-32">
                  {renderMinOrderValueMessage(
                    total,
                    minValue,
                    isSubscription,
                    freeShippingThreshold,
                  )}
                </p>
              </div>
            )}
            <div className={`flex h-10 gap-5 ${justify}`}>
              <Button
                type="button"
                className={'mobile-375:!px-2'}
                theme="primary"
                disabled={!(total >= minValue) || loading}
                onClick={() => checkoutOrSave()}
                loading={loading}
              >
                <span
                  className={`${width} pr-1 font-interMedium text-base mobile-320:text-sm`}
                >
                  {renderText(cartMode)}
                </span>
                <Image
                  src={
                    total >= minValue
                      ? '/assets/images/svg/Cart.svg'
                      : '/assets/images/svg/Cart_Disabled.svg'
                  }
                  alt=""
                  width={20}
                  height={20}
                  className="rounded-l-lg"
                />
              </Button>
            </div>
            <AuthModal
              isOpen={isAuthModalOpen}
              onRequestClose={() => setIsAuthModalOpen(false)}
              navigatedFrom="checkout"
              forceRedirectUrl="/checkout"
            />
          </div>
        </div>
      </div>
    );
  },
);

export default CartFooter;

import React from 'react';

const CartIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.15 20.05C5.66667 20.05 5.25433 19.8793 4.913 19.538C4.571 19.196 4.4 18.7833 4.4 18.3C4.4 17.8167 4.571 17.404 4.913 17.062C5.25433 16.7207 5.66667 16.55 6.15 16.55C6.63333 16.55 7.04567 16.7207 7.387 17.062C7.729 17.404 7.9 17.8167 7.9 18.3C7.9 18.7833 7.729 19.196 7.387 19.538C7.04567 19.8793 6.63333 20.05 6.15 20.05ZM15.85 20.05C15.3667 20.05 14.9543 19.8793 14.613 19.538C14.271 19.196 14.1 18.7833 14.1 18.3C14.1 17.8167 14.271 17.404 14.613 17.062C14.9543 16.7207 15.3667 16.55 15.85 16.55C16.3333 16.55 16.7457 16.7207 17.087 17.062C17.429 17.404 17.6 17.8167 17.6 18.3C17.6 18.7833 17.429 19.196 17.087 19.538C16.7457 19.8793 16.3333 20.05 15.85 20.05ZM5.025 4.25L7.55 9.55H14.375C14.425 9.55 14.475 9.53733 14.525 9.512C14.575 9.48733 14.6083 9.45 14.625 9.4L17.325 4.525C17.3583 4.45833 17.3583 4.396 17.325 4.338C17.2917 4.27933 17.2417 4.25 17.175 4.25H5.025ZM4.3 2.75H18.175C18.5917 2.75 18.9043 2.925 19.113 3.275C19.321 3.625 19.3333 3.98333 19.15 4.35L15.925 10.15C15.775 10.4333 15.5627 10.654 15.288 10.812C15.0127 10.9707 14.7167 11.05 14.4 11.05H7.1L5.95 13.175C5.9 13.2583 5.896 13.3417 5.938 13.425C5.97933 13.5083 6.05 13.55 6.15 13.55H17.6V15.05H6.15C5.48333 15.05 4.98333 14.7627 4.65 14.188C4.31667 13.6127 4.30833 13.0417 4.625 12.475L6.05 9.9L2.4 2.25H0.5V0.75H3.35L4.3 2.75Z"
        fill="black"
      />
    </svg>
  );
};
export default CartIcon;

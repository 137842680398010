const UpsellHeader = ({ title }: { title: string }) => {
  return (
    <div className="flex w-full flex-row justify-between pr-3">
      <div className="flex items-center">
        <h2 className="font-interSemibold text-lg leading-6 text-black">
          {title}
        </h2>
      </div>
    </div>
  );
};

export default UpsellHeader;

export type FilledDiscountType = {
  filledDiscount: number;
  view: string;
};

const FilledDiscount: React.FC<FilledDiscountType> = ({
  filledDiscount,
  view,
}: FilledDiscountType) => {
  const widthClass = view === 'large' ? 'lg:w-[84px]' : '';
  return (
    <>
      <li
        className={`mr-1.5 w-[28px] rounded-full bg-gradient-to-r from-success to-success-secondary text-center text-white ${widthClass}`}
        style={{ transition: 'width 500ms ease-in-out' }}
      >
        {filledDiscount}&#37;
      </li>
    </>
  );
};

export default FilledDiscount;

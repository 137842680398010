import Image from 'next/legacy/image';
import { useState } from 'react';

import BlurbStructuredText from '../StructuredText/BlurbStructuredText/BlurbStructuredText';

export interface ISeoBlurb {
  blurb?: any;
  minCharCount?: number;
}

const SeoBlurb: React.FC<ISeoBlurb> = ({ blurb, minCharCount = 100 }) => {
  const [dropdown, setDropdown] = useState(false);

  const blurbText = blurb?.value?.document?.children
    .map((child: any) => child.children.map((span: any) => span.value).join(''))
    .join('');

  const isBlurbEmpty = !blurbText || blurbText.trim() === '';

  if (isBlurbEmpty) return null;

  const isBlurbLong = blurbText.length > minCharCount;

  return (
    <div className="my-12">
      <section className="mx-auto flex max-w-[80%] justify-center text-center font-inter text-base text-[#707274] md:max-w-[75%]">
        <p>
          {dropdown || isBlurbLong
            ? blurbText
            : blurbText.substring(0, minCharCount)}
        </p>
        {!isBlurbLong && (
          <span
            className={`${
              dropdown ? '-rotate-180' : '-rotate-0'
            } -mt-1 h-8 min-w-[32px] cursor-pointer`}
            onClick={() => setDropdown(!dropdown)}
          >
            <Image
              src="/assets/icons/chevron-down-mid.svg"
              alt="chevron-down-mid"
              width={32}
              height={32}
            />
          </span>
        )}
      </section>
      {dropdown && <BlurbStructuredText content={blurb} />}
    </div>
  );
};

export default SeoBlurb;

import _ from 'lodash';
import React from 'react';

import { useStore } from '@/models/root-store';

import FilledDiscount from './FilledDiscount';
import FullDiscount from './FullDiscount';
import LinearProgress from './LinearProgress';
import UnfilledDiscounts from './UnfilledDiscounts';

export type DiscountProgressBarType = {
  view: string;
};

const DiscountProgressBar: React.FC<DiscountProgressBarType> = ({
  view,
}: DiscountProgressBarType) => {
  const { cartStore } = useStore();
  const { cartToUse } = cartStore;

  const {
    currentDiscount,
    discount,
    percentage,
    numberOfDiscountedItems,
    discountValues,
    threshold,
  } = cartToUse.overview;

  const maxDiscount: any = _.max(discountValues);
  const filledDiscount = currentDiscount < maxDiscount ? currentDiscount : '';
  const unfilledDiscounts = _.tail(
    _.filter(discountValues, (value) => value >= discount)
  );

  const fullDiscount = maxDiscount === currentDiscount;

  const width = percentage === 0 ? 0 : percentage * 100;

  const lgClasses =
    view === 'large' ? 'lg:h-[26px] lg:text-base lg:leading-[26px]' : '';
  const textLgClasses = view === 'large' ? 'lg:text-lg lg:leading-6' : '';

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="flex w-full flex-col">
          <p
            className={`pb-1 font-interMedium text-xs leading-4 ${textLgClasses}`}
          >
            <span className=" text-success-copy">
              Save up to {maxDiscount}% on MEALS{' '}
            </span>
            using our Bulk Discount.
          </p>
        </div>
        {numberOfDiscountedItems >= 0 && (
          <ul
            className={`flex h-[18px] w-full items-center justify-between rounded-full font-interMedium text-xxs leading-[18px] ${lgClasses}`}
          >
            {filledDiscount ? (
              <FilledDiscount filledDiscount={filledDiscount} view={view} />
            ) : null}

            {!fullDiscount && (
              <LinearProgress
                width={width}
                numberOfDiscountedItems={numberOfDiscountedItems}
                threshold={threshold}
                discount={discount}
                view={view}
              />
            )}

            {unfilledDiscounts.map((item: any) => (
              <UnfilledDiscounts key={item} item={item} view={view} />
            ))}

            {fullDiscount && (
              <FullDiscount maxDiscount={maxDiscount} view={view} />
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default DiscountProgressBar;

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import type { NavbarLinkExtraRecordModelType } from '@/models/api';

interface ExploreLinkProps {
  extraLink: NavbarLinkExtraRecordModelType;
  onClick?: () => void;
  isLoggedIn: boolean;
  isDesktop?: boolean;
}

const ExploreLink = ({
  extraLink,
  onClick = () => {},
  isLoggedIn,
  isDesktop = false,
}: ExploreLinkProps) => {
  const {
    text,
    icon,
    desktopImage,
    mobileImage,
    relativePathLink,
    externalUrl,
    onlyForLoggedIn,
    textColor,
    extraLabel,
  } = extraLink;
  const trackOnClick = () => {
    onClick();
  };

  if (onlyForLoggedIn && !isLoggedIn) {
    return null;
  }

  return (
    <Link href={relativePathLink || externalUrl || '/'}>
      <div onClick={trackOnClick} className="group">
        <div style={{ color: textColor?.hex }} className="flex items-center">
          <Image src={icon.url} width={28} height={28} alt={text || ''} />
          <span className="ml-3 font-interSemibold leading-4">{text}</span>
        </div>
        <hr className="my-3 border-black transition-colors duration-300 group-hover:border-primary" />
        <div className="relative">
          {isDesktop ? (
            <Image
              src={desktopImage.url}
              width={206}
              height={158}
              alt={text || ''}
              className="h-[158px] rounded-lg object-cover"
            />
          ) : (
            <Image
              src={mobileImage.url}
              width={298}
              height={166}
              alt={text || ''}
              className="w-full rounded-lg object-cover"
            />
          )}
          {extraLabel && (
            <span className="absolute left-1.5 top-1.5 inline-flex rounded-full bg-lime px-1.5 py-0.5 font-interBold text-xs">
              {extraLabel}
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ExploreLink;

export type InputTypes = {
  register?: any;
  name: string;
  label: string;
  checked: boolean;
  onChange: any;
  style?: any;
  page?: any;
};

const CheckBox: React.FC<InputTypes> = ({
  register,
  name,
  label,
  checked,
  onChange,
  style = '',
  page = '',
}) => {
  const checkboxStyles =
    page === 'login'
      ? 'accent-black w-[14px] h-[14px] min-w-[14px] min-h-[14px]'
      : 'accent-success-copy h-4 w-4';
  const labelStyles = page === 'login' ? 'pl-2.5' : 'px-3';
  return (
    <div className={style}>
      <input
        {...register(name)}
        name={name}
        checked={checked}
        type="checkbox"
        className={`rounded-sm px-3 align-middle outline-none ${checkboxStyles}`}
        onChange={onChange}
      />
      <span className={`inline-block pb-2 text-xs ${labelStyles}`}>
        {label}
      </span>
    </div>
  );
};

export default CheckBox;

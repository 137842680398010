import { observer } from 'mobx-react-lite';
import React from 'react';

import NavLink from '@/components/common/MegaNav/NavLink/NavLink';
import SubLinksContainer from '@/components/common/MegaNav/SubLinksContainer/SubLinksContainer';
import { SkeletonNavLink } from '@/components/skeleton/SkeletonNavLink/SkeletonNavLink';
import type { NavbarLinkRecordModelType } from '@/models/api';
import { useStore } from '@/models/root-store';
import { Mode, SearchState } from '@/utils/constants';

interface NavLinksProps {
  navLinks?: { links: NavbarLinkRecordModelType[] };
  categories: any;
  setIsBlur: (isBlur: boolean) => void;
}

// eslint-disable-next-line react/display-name
const NavLinks: React.FC<NavLinksProps> = observer(
  ({ navLinks, categories, setIsBlur }) => {
    const {
      customerStore: { isLoggedIn },
      productStore: {
        search: { searchState },
      },
      cartStore: { draftCart },
    } = useStore();
    const mode = draftCart?.mode;
    const hideMenu = searchState !== SearchState.NULL;
    if (hideMenu || mode === Mode.EDIT_SUBSCRIPTION) return null;

    return (
      <ul
        className="mt-4 hidden flex-wrap justify-between space-x-8 px-4 text-base text-black sm:mt-0 lg:flex"
        onMouseOver={() => {
          setIsBlur(true);
        }}
        onMouseOut={() => {
          setIsBlur(false);
        }}
      >
        {!navLinks && <SkeletonNavLink />}

        {navLinks?.links?.map((l: NavbarLinkRecordModelType, i: React.Key) => {
          return l.mobileOnly ? null : (
            <NavLink key={i} navLink={l}>
              {((l.subLinks && l.subLinks.length > 0) ||
                (l.extraLinks && l.extraLinks.length > 0) ||
                l.fullMenu) && (
                <div className="overflow-y-scroll">
                  <SubLinksContainer
                    navLink={l}
                    categories={categories}
                    isLoggedIn={isLoggedIn}
                  />
                </div>
              )}
            </NavLink>
          );
        })}
      </ul>
    );
  },
);
export default React.memo(NavLinks);

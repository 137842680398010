import Image from 'next/legacy/image';
import React from 'react';

import { useMediaQuery } from '@/hooks';

interface CardProps {
  children?: React.ReactNode;
  imageSrc: string;
  title?: string | React.ReactNode;
  description?: string;
  background?: string;
  theme?: 'primaryStyle' | 'secondaryStyle';
  imageStyle?: 'imageStylePrimary' | 'imageStyleSecondary';
  contentPadding?: string;
  isDiscovery?: boolean;
  isSubscribed?: boolean;
}

type Themes = {
  primaryStyle: string;
  secondaryStyle: string;
};

const themes: Themes = {
  primaryStyle: 'flex flex-col rounded-lg',
  secondaryStyle: 'flex flex-row rounded-lg',
};

type Styles = {
  imageStylePrimary: string;
  imageStyleSecondary: string;
  [key: string]: string;
};

const styles: Styles = {
  imageStylePrimary: 'rounded-t-lg',
  imageStyleSecondary: 'rounded-l-lg md:rounded-none md:rounded-l-lg',
};

export const Card: React.FC<CardProps> = ({
  imageSrc,
  title,
  description,
  children,
  background = 'bg-white',
  theme = 'primaryStyle',
  imageStyle = 'imageStylePrimary',
  contentPadding = 'p-6',
  isDiscovery = false,
  isSubscribed = false,
}: CardProps) => {
  const themeStyle = themes[theme];
  const themeClass = `${themeStyle} w-full ${background} ${
    isDiscovery && 'cursor-pointer'
  }`;
  const styleClass = styles[imageStyle];
  const imageClass = `${styleClass}`;

  const isMobile = useMediaQuery(`(max-width:640px)`);
  const isMedium = useMediaQuery(`(max-width:1023px)`);
  const width = isMobile ? 327 : 384;
  const height = isMobile ? 88 : 150;
  const discoveryWidth = isMedium ? 104 : 130;

  return (
    <div
      className={`flex w-full justify-center pt-6 ${
        isSubscribed && 'xl:h-[186px] xl:pt-0'
      }`}
    >
      <div className={themeClass}>
        <Image
          className={`${imageClass}`}
          src={imageSrc}
          alt=""
          width={isDiscovery ? discoveryWidth : width}
          height={height}
          layout={!isDiscovery ? 'responsive' : 'intrinsic'}
          objectFit="cover"
        />
        <div
          className={`relative flex flex-col justify-start ${contentPadding} ${
            isDiscovery && 'w-3/5'
          }`}
        >
          <div
            className={`${
              isDiscovery ? 'font-interBold' : 'font-interMedium'
            } text-base leading-[22px]`}
          >
            {title}
          </div>
          <div
            className={`py-2 font-interMedium text-base leading-[22px] text-black ${
              isDiscovery && 'pt-2 pb-4'
            }`}
          >
            {description}
          </div>
          <div className="pt-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

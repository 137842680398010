import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import type { NavbarBannerRecordModelType } from '@/models/api/NavbarBannerRecordModel';

interface DiscoveryBannerProps {
  banner: NavbarBannerRecordModelType;
}

const DiscoveryBanner = ({ banner }: DiscoveryBannerProps) => {
  const { text, bgcolor, mobileImage, desktopImage, link } = banner;

  return (
    <Link href={link || '/'}>
      <div
        className="mx-auto mt-8 flex h-fit w-auto flex-col items-center justify-start rounded-b-xl rounded-t-2xl md:h-[82px] md:flex-row lg:mt-4 lg:w-[823px]"
        style={{ backgroundColor: bgcolor?.hex || '#FF0035' }}
      >
        <div className="hidden shrink-0 md:flex">
          <Image
            src={desktopImage.url}
            width={197}
            height={81}
            alt={desktopImage.alt}
            className="h-full rounded-l-xl"
          />
        </div>
        <div className="flex w-full shrink-0 md:hidden">
          <Image
            src={mobileImage.url}
            width={298}
            height={156}
            alt={mobileImage.alt}
            className="w-full rounded-t-xl"
          />
        </div>
        <div className="flex w-full flex-col items-center justify-between px-4 py-6 md:w-11/12 md:flex-row md:px-7 lg:px-9">
          <div className="text-center font-interSemibold text-base text-white md:text-left md:font-interBold lg:text-center lg:text-lg">
            {text}
          </div>
          <span className="mt-8 hidden items-center justify-end md:mt-0 lg:flex">
            <Image
              src={'https://www.datocms-assets.com/81001/1707886265-arrow.svg'}
              width={19}
              height={16}
              alt={''}
              className="pb-1 lg:pb-1"
            />
          </span>
        </div>
      </div>
    </Link>
  );
};

export default DiscoveryBanner;

import React from 'react';

import Button from '@/components/common/Button/Button';
import { Modal } from '@/components/common/Modal/Modal';

interface ModalProps {
  isOpen: boolean;
  keepEditing: () => void;
  closeWithoutSaving: () => void;
}

export const ExitModal: React.FC<ModalProps> = ({
  isOpen,
  keepEditing,
  closeWithoutSaving,
}: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => keepEditing()}
      headerTitle={''}
      customContentStyles={{ borderRadius: '16px' }}
    >
      <div className="mx-auto w-auto max-w-sm lg:max-w-xl">
        <div className="flex w-full flex-col bg-white">
          <h3 className="px-3 pt-10 text-center font-interMedium text-2xl leading-7 text-black lg:px-16 lg:text-5xl lg:leading-10">
            Are you sure you want to <br />
            exit before saving your <br />
            changes?
          </h3>
          <div className="flex flex-col items-center justify-end px-6 pt-8 pb-6 lg:pt-12">
            <Button theme="tertiary" type="button" onClick={keepEditing}>
              <span className="px-2 py-1 font-interMedium text-base md:text-xl">
                No, keep editing
              </span>
            </Button>
            <button
              className="px-2 pt-4 font-interMedium text-base text-attention outline-none focus:outline-none lg:pt-8"
              type="button"
              onClick={closeWithoutSaving}
            >
              Yes, exit without saving
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

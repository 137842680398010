import { render as toPlainText } from 'datocms-structured-text-to-plain-text';
import { isLink, isParagraph } from 'datocms-structured-text-utils';
import _ from 'lodash';
import Link from 'next/link';
import React from 'react';
import { renderNodeRule, StructuredText } from 'react-datocms';

interface StructuredTextProps {
  content: any;
}

const BlurbStructuredText: React.FC<StructuredTextProps> = ({
  content,
}: StructuredTextProps) => {
  return (
    <div className="container flex justify-center break-words px-4 text-center">
      <StructuredText
        data={content}
        customNodeRules={[
          renderNodeRule(isParagraph, ({ children, key }) => {
            return (
              <p
                className="font-inter text-base leading-6 text-[#707274]"
                key={key}
              >
                {_.tail(children)}
              </p>
            );
          }),
          renderNodeRule(isLink, ({ node, key }) => {
            const linkUrl = node.url.replace(
              /https?:\/\/(www\.)?my(muscle|code)chef\.com/,
              ''
            );
            const isInternal = linkUrl.startsWith('/');
            const linkText = toPlainText(node);
            return (
              <span
                key={key}
                className="cursor-pointer text-[#707274] underline"
              >
                {isInternal ? (
                  <Link href={linkUrl} legacyBehavior>
                    {linkText}
                  </Link>
                ) : (
                  <a href={linkUrl} target="_blank" rel="noreferrer">
                    {linkText}
                  </a>
                )}
              </span>
            );
          }),
        ]}
      />
    </div>
  );
};

export default BlurbStructuredText;

export type UnfilledDiscountsType = {
  item: number;
  view: string;
};

const UnfilledDiscounts: React.FC<UnfilledDiscountsType> = ({
  item,
  view,
}: UnfilledDiscountsType) => {
  const lgClasses = view === 'large' ? 'lg:w-[78px] lg:leading-[26px]' : '';
  return (
    <>
      <li
        className={`mr-1.5 w-7 rounded-full bg-off-grey text-center leading-[18px] text-grey-dark ${lgClasses}`}
      >
        {item}&#37;
      </li>
    </>
  );
};

export default UnfilledDiscounts;

export type LinearProgressType = {
  width: number;
  numberOfDiscountedItems: number;
  threshold: number;
  discount: number;
  view: string;
};

const LinearProgress: React.FC<LinearProgressType> = ({
  width,
  numberOfDiscountedItems,
  threshold,
  discount,
  view,
}: LinearProgressType) => {
  let minWidthClass =
    width > 0
      ? 'min-w-[42px] pr-[1px] lg:pr-0.5'
      : 'min-w-[38px] pr-[1px] lg:pr-[1.5px]';
  let widthClass = '';
  if (view === 'large') {
    minWidthClass += ' lg:min-w-[64px]';
    widthClass = 'lg:min-w-[60px] lg:h-[22px]';
  }

  return (
    <>
      <li className="relative mr-1.5 flex h-full flex-1 items-center rounded-full bg-off-grey pr-5">
        <div
          className={`flex h-full items-center justify-end rounded-full bg-gradient-to-r from-success to-success-secondary ${minWidthClass}`}
          style={{
            width: `${width}%`,
            transition: `width ${width > 0 ? '500ms' : '1000ms'} ease-in-out`,
          }}
        >
          <div
            className={`z-10 flex h-4 min-w-[36px] items-center justify-center rounded-full bg-white text-center text-success-copy ${widthClass}`}
          >
            {numberOfDiscountedItems}/{threshold}
          </div>
        </div>
        <span className="absolute top-0 right-2 text-success-copy">
          {discount}&#37;
        </span>
      </li>
    </>
  );
};

export default LinearProgress;

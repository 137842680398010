import { ProductGrid } from '@/components/menu/ProductGrid/ProductGrid';
import { UpsellsSkeletonContainer } from '@/components/skeleton/UpsellsSkeletonContainer/UpsellsSkeletonContainer';
import type { ProductModelType } from '@/models/api';
import { useStore } from '@/models/root-store';

export type UpsellsProductType = {
  productsList: ProductModelType[] | undefined;
  loading: boolean;
};

export const UpsellsProductContainer: React.FC<UpsellsProductType> = ({
  productsList,
  loading,
}) => {
  const store = useStore();
  const {
    cartStore: { draftCart },
    cartStore,
  } = store;
  const { cartToUse } = cartStore;
  const { menuBuilderPlan } = cartToUse;
  const { action, selectedPlan } = draftCart;
  const planRoute: string = selectedPlan?.planRoute || '';
  const handleSwap = selectedPlan?.swapLineItem;
  const selectedItemPosition = selectedPlan?.selectedItemPosition;
  if (loading) {
    return <UpsellsSkeletonContainer upsellDesktopView={true} />;
  }
  return (
    <div className="min-h-screen max-w-[214px]">
      <ProductGrid
        action={action}
        products={productsList}
        orderPlan={menuBuilderPlan}
        handleSwap={handleSwap}
        selectedItemPosition={selectedItemPosition}
        planRoute={planRoute}
        planIdToView={draftCart?.planIdToView}
        mode={draftCart.mode}
        upsellView
      />
    </div>
  );
};

import Head from 'next/head';
import { NextSeo } from 'next-seo';

import { AppConfig } from '@/utils/app-config';
import type { FAQSchema } from '@/utils/faqFormatter';

type IMetaProps = {
  title: string;
  description: string;
  canonical?: string;
  ogImage?: string;
  noIndexing?: boolean;
  seoFAQSchema?: FAQSchema | null;
};

const Meta = (props: IMetaProps) => {
  const noIndexing =
    process.env.NEXT_PUBLIC_ALLOW_INDEXING !== 'true' || props.noIndexing;

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
        {props.seoFAQSchema && (
          <script type="application/ld+json">
            {JSON.stringify(props.seoFAQSchema)}
          </script>
        )}
      </Head>
      <NextSeo
        noindex={noIndexing}
        nofollow={noIndexing}
        title={props.title}
        description={props.description}
        canonical={props.canonical}
        openGraph={{
          title: props.title,
          description: props.description,
          url: props.canonical,
          locale: AppConfig.locale,
          site_name: AppConfig.site_name,
          ...(props.ogImage && { images: [{ url: props.ogImage }] }),
        }}
      />
    </>
  );
};

export { Meta };

import Link from 'next/link';
import React from 'react';

export default function NavLink(props: any) {
  const { sectionData } = props;
  return (
    <>
      {sectionData.sectionLinks.map((item: any, index: React.Key) => {
        return item.accessibilityText ? (
          <li
            className="flex items-center pb-[9.6px] last:pb-8 hover:text-[#d8173c] hover:underline lg:max-w-[100px] lg:last:pb-0"
            key={index}
          >
            {item.internalLink ? (
              <Link href={item.internalLink} legacyBehavior>
                {item.text}
              </Link>
            ) : (
              <a href={item.externalLink}>{item.text}</a>
            )}
          </li>
        ) : null;
      })}
    </>
  );
}

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import type { NavbarLinkExtraRecordModelType } from '@/models/api';

interface ExtraLinkProps {
  extraLink: NavbarLinkExtraRecordModelType;
  onClick?: () => void;
  isLoggedIn: boolean;
  isDesktop?: boolean;
}

const ExtraLink = ({
  extraLink,
  onClick = () => {},
  isLoggedIn,
  isDesktop = false,
}: ExtraLinkProps) => {
  const {
    text,
    backgroundColour,
    icon,
    relativePathLink,
    externalUrl,
    onlyForLoggedIn,
    textColor,
    onlyShowIcon,
  } = extraLink;
  const trackOnClick = () => {
    onClick();
  };

  if (onlyForLoggedIn && !isLoggedIn) {
    return null;
  }

  return (
    <Link href={relativePathLink || externalUrl || '/'}>
      {isDesktop ? (
        <div
          style={{
            backgroundColor: backgroundColour.hex,
            color: textColor.hex,
          }}
          className={`mb-0 hidden h-[59px] w-[120px] items-center ${
            onlyShowIcon ? 'justify-center' : 'justify-between'
          } rounded-md px-3 lg:mb-4 lg:flex lg:h-16`}
          onClick={trackOnClick}
        >
          {!onlyShowIcon && (
            <span className="font-interBold text-xs leading-4">{text}</span>
          )}
          <Image
            src={icon.url}
            width={!onlyShowIcon ? 32 : 123}
            height={!onlyShowIcon ? 32 : 24}
            alt={text || ''}
          />
        </div>
      ) : (
        <div
          style={{
            backgroundColor: backgroundColour.hex,
            color: textColor.hex,
          }}
          className={`flex items-center ${
            onlyShowIcon ? 'justify-center' : 'justify-between'
          } min-h-16 rounded-md  p-3`}
          onClick={trackOnClick}
        >
          <div className="flex items-center">
            {icon && (
              <div className="mr-2 rounded-full p-1">
                <Image
                  src={icon.url}
                  width={!onlyShowIcon ? 32 : 123}
                  height={!onlyShowIcon ? 32 : 24}
                  alt={text || ''}
                />
              </div>
            )}
            {!onlyShowIcon && (
              <span className="font-interBold text-base">{text}</span>
            )}
          </div>
        </div>
      )}
    </Link>
  );
};

export default ExtraLink;

import { useAuth } from '@clerk/nextjs';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { useStore } from '@/models/root-store';

export const ImpersonationBanner: React.FC = observer(() => {
  const router = useRouter();
  const { customerStore } = useStore();
  const { userId, actor, signOut } = useAuth();

  const onLogoutClick = async () => {
    await signOut();
    customerStore.logout();
    router.push('/');
  };

  if (!actor || !userId) return null;

  return (
    <div className="bg-attention text-white text-center p-4">
      Impersonating {customerStore.customer?.email}
      <button
        className="ml-3 bg-white text-attention p-2 px-3 rounded-md"
        onClick={onLogoutClick}
      >
        Stop impersonating
      </button>
    </div>
  );
});

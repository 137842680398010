export type FullDiscountType = {
  maxDiscount: number;
  view: string;
};

const FullDiscount: React.FC<FullDiscountType> = ({
  maxDiscount,
  view,
}: FullDiscountType) => {
  const widthClass = view === 'large' ? 'lg:leading-[26px]' : '';
  return (
    <>
      <li
        className={`w-full rounded-full bg-gradient-to-r from-success to-success-secondary pl-3 leading-[18px] text-white ${widthClass}`}
      >
        You’re saving {maxDiscount}% 🎉
      </li>
    </>
  );
};

export default FullDiscount;

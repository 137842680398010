import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

import type { MenuHierarchyRecordModelType } from '@/models/api';
import { useStore } from '@/models/root-store';

interface CategoriesContainerProps {
  level: number;
  categories: any[];
  setSubCategories: (categories: any) => void;
  noBorder?: boolean;
  parentCategory?: MenuHierarchyRecordModelType | undefined;
  subCategory?: MenuHierarchyRecordModelType | undefined;
  mainCategory?: MenuHierarchyRecordModelType | undefined;
  setParentCategory: (
    category: MenuHierarchyRecordModelType | undefined,
  ) => void;
}

const DesktopCategories = ({
  level,
  categories,
  setSubCategories,
  noBorder = false,
  parentCategory,
  mainCategory,
  setParentCategory,
}: CategoriesContainerProps) => {
  const router = useRouter();
  const { productStore } = useStore();
  const { filters } = productStore;

  const isTouchDevice = window.matchMedia('(any-pointer: coarse)').matches;

  const borderStyle = noBorder ? '' : 'border-l border-grey-lite';

  const onMouseOver = (category: MenuHierarchyRecordModelType) => {
    setSubCategories(category.childrenCategories);
    setParentCategory(category);
  };

  const onClick = (category: MenuHierarchyRecordModelType, e: any) => {
    filters.clearFilters();
    let categoryLink = category.slug;
    switch (level) {
      case 2:
        categoryLink = `${parentCategory?.slug}/${category.slug}`;
        break;
      case 3:
      case 4:
        categoryLink = `${mainCategory?.slug}/${parentCategory?.slug}/${category.slug}`;
        break;
      default:
        break;
    }

    if (isTouchDevice && category.childrenCategories?.length > 0) {
      e.preventDefault();
      e.stopPropagation();
      onMouseOver(category);
    } else {
      router.push(`/menu/${categoryLink}`);
    }
  };

  const onClickAll = (category: MenuHierarchyRecordModelType) => {
    filters.clearFilters();
    let categoryLink = category.slug;
    switch (level) {
      case 3:
        categoryLink = `${mainCategory?.slug}/${category.slug}`;
        break;
      default:
        break;
    }
    router.push(`/menu/${categoryLink}`);
  };

  if (!categories) {
    return null;
  }

  return (
    <ul
      className={`relative flex w-auto flex-col px-4 py-1 leading-4 ${borderStyle}`}
    >
      {categories && categories.length > 0 && parentCategory && level !== 4 && (
        <li
          key={parentCategory.key}
          className="h-10 w-52 cursor-pointer"
          onClick={() => onClickAll(parentCategory)}
        >
          <div
            className={`flex justify-between rounded-md p-2 text-black transition hover:bg-grey-liter`}
          >
            <span className="flex">
              <span className="py-1 text-base">All {parentCategory?.name}</span>
            </span>
          </div>
        </li>
      )}
      {categories?.map((category, index) => (
        <li
          key={index}
          className="h-10 w-52 cursor-pointer"
          onMouseOver={() => onMouseOver(category)}
          onClick={(e) => onClick(category, e)}
        >
          <div
            className={`flex justify-between rounded-md p-2 text-black transition hover:bg-grey-liter`}
          >
            <span className="flex">
              <span className="whitespace-nowrap py-1 text-base">
                {category?.name}
              </span>
              {category.extraLabel && (
                <span className="ml-4 inline-flex h-6 rounded-[40px] bg-lemon px-2 py-1 font-interSemibold text-xs">
                  {category.extraLabel}
                </span>
              )}
            </span>
            {category.childrenCategories &&
              category.childrenCategories.length > 0 && (
                <Image
                  width={24}
                  height={24}
                  src="https://www.datocms-assets.com/81001/1701308267-arrow-down.svg"
                  alt=""
                />
              )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default DesktopCategories;

import { SkeletonCard } from '../SkeletonCard/SkeletonCard';

export type UpsellsSkeletonContainerType = {
  upsellDesktopView?: boolean;
  upsellMobileView?: boolean;
};

const renderSkeletonCards = (
  numberOfCards: number,
  upsellDesktopView = false,
) => {
  const skeletonCards = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numberOfCards; i++) {
    skeletonCards.push(
      <SkeletonCard key={i} upsellDesktopView={upsellDesktopView} />,
    );
  }
  return skeletonCards;
};

export const UpsellsSkeletonContainer: React.FC<
  UpsellsSkeletonContainerType
> = ({ upsellMobileView = false, upsellDesktopView = false }) => {
  return (
    <div className="flex min-h-screen flex-col bg-off-white">
      <div
        className={
          upsellMobileView
            ? 'grid grid-cols-2 gap-4 px-6 py-8 sm:grid-cols-3 lg:gap-6 xl:grid-cols-4 xl:gap-8' // mobileContainer
            : 'grid w-[166px] max-w-[214px] grid-cols-2 gap-4 sm:grid-cols-1 lg:gap-6 xl:grid-cols-1 xl:gap-8' // productContainer
        }
      >
        {renderSkeletonCards(4, upsellDesktopView)}
      </div>
      {upsellMobileView && (
        <div
          className="absolute bottom-0 left-0 z-[9999999] mt-0 flex h-[72px] w-full flex-col justify-between bg-white"
          style={{
            boxShadow:
              '0px 32px 64px rgba(0, 0, 0, 0.1876), 0px 2px 21px rgba(0, 0, 0, 0.1474)',
          }}
        />
      )}
    </div>
  );
};

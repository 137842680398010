import _ from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import React, { useCallback } from 'react';

import AddButton from '@/components/common/AddButton/AddButton';
import type { ProductModelType } from '@/models/api';
import { useStore } from '@/models/root-store';
import { Mode } from '@/utils/constants';
import { trackProductClicked } from '@/utils/track/tracker.helper';

import { PackAndDiscount } from '../PackAndDiscount/PackAndDiscount';
import { PrimaryDisplayTag } from '../PrimaryDisplayTag/PrimaryDisplayTag';

export type OtherProductTileType = {
  product: ProductModelType;
  refreshUpSells: () => void;
};

export const OtherProductTile: React.FC<OtherProductTileType> = ({
  product,
  refreshUpSells,
}) => {
  const { cms, promotionTotal, grossPrice, slug } = product;
  const productImage = cms ? cms.tileImage?.url : '';

  const {
    cartStore,
    productStore: { productsLookup },
  } = useStore();
  const { cart, cartToUse } = cartStore;
  const { mode } = cartToUse;
  const { closeCart } = cart;
  const { menuBuilderPlan } = cartToUse;
  const handleAddProduct = useCallback(() => {
    menuBuilderPlan?.increaseLineItem(
      product,
      1,
      'upsell',
      'Item added to cart',
    );
    refreshUpSells();
  }, [menuBuilderPlan]);
  const item = productsLookup[product.sku];
  const itemDiscount =
    item && item.getDiscountedPrice(menuBuilderPlan?.discount);
  const packs =
    product.hasSubProducts && product.subProducts
      ? _.sumBy(product.subProducts, (sub) => sub.qty)
      : 0;
  const productName = product.cms.primaryName ?? product.name;

  const trackOnclick = () => {
    try {
      trackProductClicked(product, 'upsell');
    } catch (error) {
      // ignore error
    }
  };

  return (
    <div className="block px-6">
      <div className="relative mb-4 flex rounded-lg bg-white">
        <div className="relative flex" onClick={trackOnclick}>
          {productImage &&
            (mode !== Mode.EDIT_SUBSCRIPTION ? (
              <Link
                href={{
                  pathname: `/products/${slug}`,
                  query: {
                    ...(mode && { mode }),
                  },
                }}
                className="h-[148px]"
              >
                <Image
                  src={productImage}
                  alt={`Image of ${product.name}`}
                  width={130}
                  height={148}
                  className="h-[148px] rounded-l-lg object-cover hover:cursor-pointer"
                  onClick={closeCart}
                />
              </Link>
            ) : (
              <Image
                src={productImage}
                alt={`Image of ${product.name}`}
                width={130}
                height={148}
                className="h-[148px] rounded-l-lg object-cover"
              />
            ))}
        </div>
        <div className="flex flex-col pl-3 pt-2" onClick={trackOnclick}>
          {mode !== Mode.EDIT_SUBSCRIPTION ? (
            <Link
              href={{
                pathname: `/products/${slug}`,
                query: {
                  ...(mode && { mode }),
                },
              }}
              legacyBehavior
            >
              <h5
                onClick={closeCart}
                className="max-w-[167px] pb-1 font-interMedium text-sm leading-snug hover:cursor-pointer"
              >
                {productName}
              </h5>
            </Link>
          ) : (
            <h5 className="max-w-[167px] pb-1 font-interMedium text-sm leading-snug">
              {productName}
            </h5>
          )}

          {/* primary tag */}
          {!product.hasSubProducts && product.cms?.primaryDisplayTag && (
            <PrimaryDisplayTag
              tag={product.cms?.primaryDisplayTag}
              page={'cart'}
            />
          )}

          {/* discount */}
          {product.hasSubProducts && promotionTotal && (
            <PackAndDiscount
              promotionTotal={promotionTotal}
              grossPrice={grossPrice}
              packs={packs}
              quantityTag={cms?.quantityTag}
            />
          )}

          <p className="flex py-3 font-interMedium leading-4">
            <span>${itemDiscount?.toFixed(2)}</span>
            {itemDiscount !== grossPrice || promotionTotal ? (
              <del className="pl-1 font-interMedium text-xs text-grey-dark">
                ${grossPrice?.toFixed(2)}
              </del>
            ) : null}
          </p>
        </div>

        {/* Add button */}
        <div className="absolute bottom-2 right-2">
          <AddButton onClick={handleAddProduct} size="small" />
        </div>
      </div>
    </div>
  );
};

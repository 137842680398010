import { observer } from 'mobx-react-lite';

import { useStore } from '@/models/root-store';
import { Mode, SearchState } from '@/utils/constants';

interface MenuBarProps {
  onClick: () => void;
  value: boolean;
}
const MenuBar = observer(({ onClick, value }: MenuBarProps) => {
  const {
    productStore: {
      search: { searchState },
    },
  } = useStore();
  const {
    cartStore: { draftCart },
  } = useStore();
  const mode = draftCart?.mode;
  const hideMenu = searchState !== SearchState.NULL;
  if (hideMenu || mode === Mode.EDIT_SUBSCRIPTION) return null;
  return (
    <div className="ml-2 flex flex-none lg:hidden">
      <span onClick={onClick} className="flex items-center justify-center">
        {value ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="30"
            height="30"
            fill="black"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="30"
            height="30"
            fill="black"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
          </svg>
        )}
      </span>
    </div>
  );
});

export default MenuBar;

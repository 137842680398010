import Image from 'next/legacy/image';
import React from 'react';

export type GiftCardLineItemType = {
  item: any;
  handleRemoveGift: (giftCardId: string) => void;
};

const GiftCardLineItems: React.FC<GiftCardLineItemType> = ({
  item,
  handleRemoveGift,
}: GiftCardLineItemType) => {
  const image = '/assets/icons/MYMC-GiftCard-Icon.jpg';

  return (
    <div className="block px-6 last:pb-10">
      <div className="relative mb-4 flex rounded-lg bg-off-white">
        <div className="relative flex">
          {image && (
            <Image
              src={image}
              alt="E-Gift Card"
              width={104}
              height={104}
              className="rounded-l-lg"
            />
          )}
        </div>
        <div className="flex flex-col p-3">
          <h5 className="max-w-[167px] truncate pb-2.5 font-interMedium text-xs leading-snug">
            Gift Card Purchase for {item.recipientName}
          </h5>
          <p className="font-interMedium text-xs leading-4">
            ${item.netPrice.toFixed(2)}
          </p>
        </div>
        <p className="absolute right-2 top-2 cursor-pointer">
          <Image
            src="/assets/images/svg/delete.svg"
            alt="Delete Cart"
            width={20}
            height={15}
            className="rounded-l-lg"
            onClick={() => handleRemoveGift(item.giftCardId)}
          />
        </p>
      </div>
    </div>
  );
};

export default GiftCardLineItems;

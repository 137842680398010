import { observer } from 'mobx-react-lite';
import React from 'react';

import type { AuthContainerProps } from '@/components/auth/AuthContainer/AuthContainer';
import AuthContainer from '@/components/auth/AuthContainer/AuthContainer';
import type { ModalProps } from '@/components/common/Modal/Modal';
import { Modal } from '@/components/common/Modal/Modal';
import { useStore } from '@/models/root-store';

export type AuthModalProps = Omit<ModalProps, 'children'> & AuthContainerProps;

const AuthModal: React.FC<AuthModalProps> = observer(
  (props: AuthModalProps) => {
    const { isOpen, onRequestClose, ...rest } = props;
    const {
      customerStore: { isLoggedIn },
    } = useStore();

    if (isLoggedIn) {
      return null;
    }

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        customContentStyles={{ maxWidth: 400 }}
        closeIconFile="/assets/icons/close_icon_2.svg"
        closeIconStyle="w-3 h-3 top-4 right-4"
        preventScroll
      >
        <AuthContainer {...rest} position="modal" />
      </Modal>
    );
  }
);

export default AuthModal;

import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from '@/models/root-store';
import { SearchState } from '@/utils/constants';

interface CancelButtonProps {
  onClick: () => void;
}

const CancelButton: React.FC<CancelButtonProps> = observer(({ onClick }) => {
  const {
    productStore: {
      search: { searchState },
    },
  } = useStore();
  if (searchState === SearchState.NULL) return null;

  return (
    <button
      onClick={onClick}
      disabled={searchState === SearchState.PENDING}
      className="h-full gap-2.5 self-center whitespace-nowrap rounded-md p-2 text-base font-medium leading-none tracking-normal text-blue-600 hover:bg-grey-liter"
    >
      Cancel
    </button>
  );
});

export default CancelButton;

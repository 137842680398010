import React from 'react';

import GiftCardLineItems from '../GiftCardLineItems/GiftCardLineItems';

export type GiftCardTypes = {
  giftItems: Array<any>;
  handleRemoveGift: (giftCardId: string) => void;
};

const GiftCardContainer: React.FC<GiftCardTypes> = ({
  giftItems,
  handleRemoveGift,
}: GiftCardTypes) => {
  return (
    <>
      {giftItems && (
        <>
          <div className="flex w-full items-center justify-between px-6 pb-7">
            <div className="flex items-center">
              <h2 className="mr-3 font-interMedium text-2xl text-black">
                Gift Cards
              </h2>
              <span className="flex w-8 items-center justify-center rounded-full border border-grey-dark py-1 px-2.5 font-interMedium text-base leading-none text-grey-dark">
                {giftItems.length}
              </span>
            </div>
          </div>
          {giftItems.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <GiftCardLineItems
                item={item}
                handleRemoveGift={handleRemoveGift}
              />
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default GiftCardContainer;

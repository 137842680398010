import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { useMediaQuery } from '@/hooks';
import staticLinks from '@/mock-data/meganav-data.json';
import { useStore } from '@/models/root-store';
import { Mode, SearchState } from '@/utils/constants';

const Logo = observer(() => {
  const {
    cartStore: { draftCart },
    productStore: {
      search: { searchState, resetSearch },
    },
  } = useStore();
  const isMobile = useMediaQuery(`(max-width:639px)`);
  const hideMenu = searchState !== SearchState.NULL && isMobile;
  if (hideMenu) return null;
  const editSubscription = draftCart.mode === Mode.EDIT_SUBSCRIPTION;
  return (
    <Link
      href="/"
      onClick={(e) => {
        if (editSubscription) {
          e.preventDefault();
          return;
        }
        resetSearch();
      }}
      className={`relative flex ${editSubscription ? 'w-fit cursor-default' : 'w-full cursor-pointer'} justify-between md:w-auto md:justify-center lg:static lg:block lg:w-auto lg:justify-start`}
    >
      <Image
        src={staticLinks.logo?.url}
        alt={staticLinks.logo?.alt}
        width={52}
        height={27}
        className="ml-7 block lg:hidden mobile-320:ml-3"
      />
      <Image
        src={staticLinks.logo?.url}
        alt={staticLinks.logo?.alt}
        width={77}
        height={39}
        className="hidden lg:block"
      />
    </Link>
  );
});
export default Logo;

import React from 'react';

import LineItem from '@/components/cart/LineItem/LineItem';
import type { OrderPlan } from '@/models/cart/order-plan';
import { categoryOrder } from '@/utils/constants';

export type CustomPlanTypes = {
  categories: Array<any>;
  plan: OrderPlan;
};

const CatLineQty = (data: any) => {
  return data.reduce((sum: number, item: any) => sum + item.qty, 0);
};

const CustomPlan: React.FC<CustomPlanTypes> = ({
  categories,
  plan,
}: CustomPlanTypes) => {
  categories.sort(
    (a, b) => categoryOrder.indexOf(a.title) - categoryOrder.indexOf(b.title)
  );

  const formatCategory = (category: string) => {
    return (
      category.slice(0, 1) + category.slice(1, category.length).toLowerCase()
    );
  };

  return (
    <div>
      {categories &&
        categories.map((category: any) => (
          <div key={category.title}>
            <div className="flex w-full items-center justify-between px-6 pb-7">
              <div className="flex items-center">
                <h2 className="mr-3 font-interSemibold text-2xl text-black">
                  {formatCategory(category.title)}
                </h2>
                <span className="flex w-8 items-center justify-center rounded-full border border-grey-dark py-1 px-2.5 font-interMedium text-base leading-none text-grey-dark">
                  {CatLineQty(category.data)}
                </span>
              </div>
            </div>
            {category.data.map((product: any) => (
              <LineItem
                key={product?.product?.sku}
                plan={plan}
                product={product}
              />
            ))}
          </div>
        ))}
    </div>
  );
};

export default CustomPlan;

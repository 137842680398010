import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';

import useClickOutside from '@/hooks/useClickOutside';
import { useStore } from '@/models/root-store';
import { Mode, SearchModes, SearchState } from '@/utils/constants';
import { getSearchMode, searchBarStyles } from '@/utils/helpers';

interface SearchInputProps {
  setBlur: (isBlur: boolean) => void;
}

const SearchInput: React.FC<SearchInputProps> = observer(({ setBlur }) => {
  const {
    cartStore: { draftCart },
    productStore: { search },
    generalStore: { ready },
  } = useStore();
  const {
    isSearchPending,
    searchState,
    setSearchState,
    isValidSearch,
    setText,
    resetText,
    searchResultHeader,
  } = search;
  let { searchValue } = search;

  const { baseStyles, notExpanded } = searchBarStyles(
    searchState,
    draftCart.mode,
  );
  const router = useRouter();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const expandSearch = () => {
    setSearchState(SearchState.ACTIVE);
    setBlur(true);
  };
  const expanded = searchState !== SearchState.NULL;

  useEffect(() => {
    if (!ready) return;
    if (searchState === SearchState.ACTIVE) {
      inputRef.current?.focus();
    }
  }, [searchState, ready]);

  const handleOutsideClick = () => {
    setBlur(false);
    setSearchState(SearchState.NULL);
  };

  useClickOutside(wrapperRef, handleOutsideClick, 'search');

  const handleSearchSubmit = async () => {
    searchValue = searchValue.trim();
    if (!isValidSearch || isSearchPending) {
      return;
    }

    const searchMode = getSearchMode(router.asPath as string);
    switch (searchMode) {
      case SearchModes.MENU_SEARCH:
        await router.push(
          {
            pathname: '/menu/search',
            query: { searchText: searchValue },
          },
          undefined,
          { shallow: true },
        );
        setSearchState(SearchState.PENDING);
        break;
      case SearchModes.NULL:
      case SearchModes.Menu:
        await router.push({
          pathname: '/menu/search',
          query: { searchText: searchValue },
        });
        setSearchState(SearchState.PENDING);
        break;
      case SearchModes.SWAP_EDIT_CART_SEARCH:
      case SearchModes.SWAP_EDIT_CART:
        await router.push({
          pathname: '/swap/search',
          query: { mode: Mode.EDIT_CART, searchText: searchValue },
        });
        setSearchState(SearchState.PENDING);
        break;
      case SearchModes.SWAP_SEARCH:
        await router.push(
          {
            pathname: '/swap/search',
            query: { searchText: searchValue },
          },
          undefined,
          { shallow: true },
        );
        setSearchState(SearchState.PENDING);
        break;
      case SearchModes.SWAP_EDIT_SUBSCRIPTION_SEARCH:
      case SearchModes.SWAP_EDIT_SUBSCRIPTION:
        await router.push({
          pathname: '/swap/search',
          query: { mode: Mode.EDIT_SUBSCRIPTION, searchText: searchValue },
        });
        setSearchState(SearchState.PENDING);
        break;
      case SearchModes.Swap:
        await router.push(
          {
            pathname: '/swap/search',
            query: { searchText: searchValue },
          },
          undefined,
          { shallow: true },
        );
        setSearchState(SearchState.PENDING);
        break;
      case SearchModes.EDIT_SUBSCRIPTION:
        await router.push({
          pathname: '/menu/search',
          query: { mode: Mode.EDIT_SUBSCRIPTION, searchText: searchValue },
        });
        setSearchState(SearchState.PENDING);
        break;
      case SearchModes.EDIT_SUBSCRIPTION_SEARCH:
        await router.push(
          {
            pathname: '/menu/search',
            query: { mode: Mode.EDIT_SUBSCRIPTION, searchText: searchValue },
          },
          undefined,
          { shallow: true },
        );
        setSearchState(SearchState.PENDING);
        break;
      default:
        break;
    }
    window.scrollTo({ top: 0 });
  };

  const handleSearchBarText = () => {
    if (!searchValue) {
      setText(searchResultHeader);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={`flex items-center ${baseStyles} ${
        expanded
          ? 'w-full min-w-[240px] sm:w-[338px] md:w-[338px] mobile-320:min-w-[220px]'
          : `w-12 ${notExpanded}`
      } h-10`}
    >
      {expanded ? (
        <>
          <div className="relative flex h-full flex-1 items-center">
            <input
              ref={inputRef}
              type="text"
              disabled={isSearchPending}
              placeholder={'Search for products'}
              className="w-full border-none bg-transparent px-2 pr-10 font-interSemibold text-base leading-none tracking-normal text-black outline-none sm:text-sm sm:font-medium"
              aria-label={'Search for products'}
              onBlur={() => {
                setBlur(false);
                setSearchState(SearchState.INACTIVE);
                handleSearchBarText();
              }}
              onFocus={() => {
                setBlur(true);
                setSearchState(SearchState.ACTIVE);
              }}
              value={searchValue}
              onChange={({ target: { value } }) => setText(value)}
              onKeyDown={(e) => {
                if (!isValidSearch) return;
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  inputRef.current?.blur();
                  handleSearchSubmit();
                }
              }}
            />
            {isValidSearch && (
              <Image
                width={7.52}
                height={10.52}
                priority={true}
                src="/assets/icons/clear-icon.svg"
                alt="Clear search"
                className="absolute right-2 top-1/2 z-10 size-5 -translate-y-1/2 cursor-pointer"
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  resetText();
                  inputRef.current?.focus();
                }}
              />
            )}
          </div>
          <div className="flex h-full w-12 items-center justify-center border-l border-neutral-200">
            <Image
              loading="lazy"
              width={18}
              height={18}
              src="/assets/icons/search.svg"
              className={`size-6 ${!isSearchPending ? 'cursor-pointer' : ''} object-contain`}
              alt="Search"
              onMouseDown={handleSearchSubmit}
            />
          </div>
        </>
      ) : (
        <div className="flex h-full w-12 cursor-pointer items-center justify-center">
          <Image
            width={18}
            height={18}
            loading="lazy"
            src={'/assets/icons/inactive_search.svg'}
            className="size-6 object-contain"
            alt="Search"
            onClick={expandSearch}
          />
        </div>
      )}
    </div>
  );
});

export default SearchInput;

import React from 'react';

const CopyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = '#006CED' } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.59985 11.4769C1.32967 11.4769 1.10486 11.3872 0.925417 11.2078C0.745972 11.0283 0.65625 10.8035 0.65625 10.5333V3.07691H1.18958V10.5333C1.18958 10.6359 1.23232 10.7299 1.3178 10.8154C1.40327 10.9008 1.49728 10.9436 1.59985 10.9436H7.45627V11.4769H1.59985ZM3.33318 9.74358C3.06301 9.74358 2.83819 9.65385 2.65875 9.47441C2.47931 9.29497 2.38958 9.07016 2.38958 8.79999V1.62049C2.38958 1.35033 2.47931 1.12552 2.65875 0.946077C2.83819 0.766633 3.06301 0.67691 3.33318 0.67691H8.91267C9.18284 0.67691 9.40765 0.766633 9.5871 0.946077C9.76654 1.12552 9.85627 1.35033 9.85627 1.62049V8.79999C9.85627 9.07016 9.76654 9.29497 9.5871 9.47441C9.40765 9.65385 9.18284 9.74358 8.91267 9.74358H3.33318ZM3.33318 9.21024H8.91267C9.01523 9.21024 9.10925 9.16751 9.19472 9.08204C9.28019 8.99658 9.32293 8.90256 9.32293 8.79999V1.62049C9.32293 1.51794 9.28019 1.42392 9.19472 1.33844C9.10925 1.25298 9.01523 1.21024 8.91267 1.21024H3.33318C3.23062 1.21024 3.1366 1.25298 3.05113 1.33844C2.96567 1.42392 2.92293 1.51794 2.92293 1.62049V8.79999C2.92293 8.90256 2.96567 8.99658 3.05113 9.08204C3.1366 9.16751 3.23062 9.21024 3.33318 9.21024Z"
        fill={fill}
      />
    </svg>
  );
};

export default CopyIcon;

import { useFeature } from '@growthbook/growthbook-react';
import * as Sentry from '@sentry/nextjs';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import type { FC } from 'react';
import React from 'react';

import Button from '@/components/common/Button/Button';
import UpwardArrow from '@/components/icons/UpwardArrow';
import type { ProductModelType } from '@/models/api';
import type { Product } from '@/shared/types';
import { Experiments, ToastEnum } from '@/utils/constants';
import { showToast } from '@/utils/helpers';

import MenuStructuredText from '../MenuStructuredText/MenuStructuredText';

export type PFCardType = {
  title?: string;
  code?: string;
  description?: string;
  onClick?: () => void;
  products?: Pick<Product, 'sku'>[];
  productsLookup?: Record<string, ProductModelType>;
  addProducts: (products: ProductModelType[]) => void;
  openCart: () => void;
};
const PreFilledCartCard: FC<PFCardType> = observer(
  ({
    title,
    description = '',
    products,
    productsLookup,
    addProducts,
    openCart,
  }: PFCardType) => {
    const cardClass =
      'relative h-full px-3 pt-8 pb-3 md:px-4 md:pt-10 xl:pt-12 md:pb-4';
    const buttonClass =
      'flex h-[26px] w-full justify-center space-x-2 px-0 py-1 font-interSemibold text-[11px] leading-[16px] md:h-[40px] md:text-sm xl:text-lg';
    const titleClass =
      'font-interBold text-xl leading-[22px] text-white md:text-4xl md:leading-[36px] xl:text-5xl';
    const descriptionClass =
      'font-interMedium leading-4 text-white md:leading-[22px]';

    const featureResult = useFeature(Experiments.PREFILLCARD);
    if (!featureResult.value) {
      return null;
    }

    const handleAddProducts = () => {
      if (products && productsLookup) {
        try {
          const productsToAdd = products
            .map((product) => productsLookup[product.sku])
            .filter((prod) => prod) as ProductModelType[];

          addProducts(productsToAdd);
          openCart();
          showToast(
            '10 meals added to cart!',
            ToastEnum.SUCCESS,
            'menu-tile-code',
          );
        } catch (error) {
          Sentry.captureException(error);
          showToast('Something went wrong', ToastEnum.ERROR, 'menu-tile-code');
        }
      }
    };

    return (
      <div className={`${cardClass}`}>
        <Image
          layout="fill"
          className="cursor-pointer rounded-2xl"
          src="https://www.datocms-assets.com/81001/1698189371-mb-prefill-card.jpg?auto=format"
          alt="Pre Fill Cart"
        />
        <div className="relative flex h-full flex-col justify-between">
          <h4 className={`relative  ${titleClass}`}>
            {title?.split(' ').map((word, index) => (
              <React.Fragment key={index}>
                {word}
                {index === 1 ? <br /> : ' '}
              </React.Fragment>
            ))}
          </h4>
          <div>
            <div className={`relative space-y-1 ${descriptionClass}`}>
              <MenuStructuredText content={description} theme="cart" />
            </div>
            <div className="group flex justify-center pt-1 md:pt-4">
              <Button
                className={`${buttonClass}`}
                onClick={handleAddProducts}
                theme="quinary"
              >
                <span>Add to cart & edit</span>
                <span className="hidden group-hover:block">
                  <UpwardArrow color="#FF0035" />
                </span>
                <span className="group-hover:hidden">
                  <UpwardArrow />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default PreFilledCartCard;

import React from 'react';

import type {
  NavbarLinkExtraRecordModelType,
  NavbarLinkRecordModelType,
  NavbarRecordModelType,
} from '@/models/api';

import ExploreLink from '../ExploreLink/ExploreLink';
import MobileNavLink from '../MobileNavLink/MobileNavLink';

interface MobileExploreLinksProps {
  navLinks: NavbarRecordModelType | undefined;
  selectedLink: NavbarLinkRecordModelType | undefined;
  setSelectedLink: (link: NavbarLinkRecordModelType) => void;
  onClick: () => void;
  isLoggedIn: boolean;
}

const MobileExploreLinks = ({
  navLinks,
  selectedLink,
  setSelectedLink,
  onClick,
  isLoggedIn,
}: MobileExploreLinksProps) => {
  return (
    <>
      {/* Primary Links */}
      {!selectedLink && (
        <ul className="flex flex-col font-interSemibold text-xl">
          {navLinks?.links?.map((navLink, index) => {
            return (
              <div key={index}>
                {navLink.linkType === 'Primary' &&
                  navLink.category === 'explore' && (
                    <MobileNavLink
                      key={navLink.text}
                      navLink={navLink}
                      setSelectedLink={setSelectedLink}
                      onClick={onClick}
                    />
                  )}
              </div>
            );
          })}
        </ul>
      )}

      {/* Sub Links */}
      {selectedLink && (
        <ul className="mb-8 flex flex-col gap-8 font-interMedium text-lg">
          {selectedLink?.extraLinks?.map(
            (navLink: NavbarLinkExtraRecordModelType) => {
              return (
                <ExploreLink
                  key={navLink.text}
                  extraLink={navLink}
                  onClick={onClick}
                  isLoggedIn={isLoggedIn}
                  isDesktop={false}
                />
              );
            },
          )}
        </ul>
      )}
    </>
  );
};

export default MobileExploreLinks;

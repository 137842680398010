import React from 'react';

import LoadingContainer from '@/components/common/LoadingContainer/LoadingContainer';

const Loader = () => {
  return (
    <div className="fixed inset-0 z-1001 block h-full w-full !bg-black/80">
      <div className="flex h-full w-full items-center justify-center">
        <LoadingContainer />
      </div>
    </div>
  );
};

export default Loader;

import { SkeletonPill } from '../SkeletonPill/SkeletonPill';

export const SkeletonNavLink: React.FC = () => {
  return (
    <div className="flex w-full animate-pulse items-center justify-center space-x-2">
      <SkeletonPill />
      <SkeletonPill />
      <SkeletonPill />
      <SkeletonPill />
      <SkeletonPill />
    </div>
  );
};

import Image from 'next/legacy/image';
import React from 'react';

import { Mode } from '@/utils/constants';

export type OrderSummaryContainerType = {
  setShowOrderSummary: (show: boolean) => void;
  cart: any;
};

const OrderSummaryContainer: React.FC<OrderSummaryContainerType> = ({
  setShowOrderSummary,
  cart,
}: OrderSummaryContainerType) => {
  const {
    lineItemTotal,
    cartTotal,
    numberOfItems,
    discount,
    discountedAmount,
    mode,
  } = cart;

  return (
    <div className="relative w-full bg-off-grey">
      <div
        className="cursor-pointer px-6 text-black"
        onClick={() => setShowOrderSummary(false)}
      >
        <div className="flex items-center justify-center border-b">
          <h3 className="py-2 pr-2 text-center font-interMedium text-sm leading-none text-black">
            Order Summary
          </h3>
          <Image
            src="/assets/images/svg/Circle_Up.svg"
            alt="Expand"
            width={15.85}
            height={15.85}
          />
        </div>
      </div>

      <div className="sticky z-20 w-full overflow-hidden rounded bg-inherit shadow-lg">
        <div className="px-6 py-4">
          <div className="flex flex-col gap-4 font-interMedium">
            <div className="flex justify-between font-interMedium">
              <p className="text-xs text-black">
                Subtotal ({numberOfItems} items)
              </p>
              <p className="text-base text-black">
                ${lineItemTotal.toFixed(2)}
              </p>
            </div>
            {discount > 0 && (
              <div className="flex justify-between">
                <p className="text-xs text-black">Order Discount</p>
                <p className="text-base text-success-copy">
                  -${discountedAmount.toFixed(2)}
                </p>
              </div>
            )}
            {mode !== Mode.EDIT_SUBSCRIPTION && (
              <div className="flex justify-between">
                <p className="-mb-2 font-interMedium text-xs leading-4 text-grey-dark">
                  Shipping & promotions applied at checkout.
                </p>
              </div>
            )}
            {/* frequency === Frequency.ONCE ? (
              <div className="flex items-center justify-center rounded-md bg-rhubarb-lite py-1.5 text-xs text-rhubarb-dark">
                <Image
                  src="/assets/images/svg/shipping.svg"
                  alt="Shipping"
                  width={24}
                  height={15}
                />
                <p className="pl-2.5 font-interMedium">
                  Get FREE SHIPPING when you subscribe 🎉
                </p>
              </div>
            ) : null */}
            {/* <div className="flex justify-between text-xs text-black"> */}
            {/*  <p>GST (incl)</p> */}
            {/*  <p className="text-base">${gst.toFixed(2)}</p> */}
            {/* </div> */}
            <div className="flex justify-between font-interMedium text-xs text-black">
              <p>Total (incl. GST)</p>
              <p className="text-base">${cartTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryContainer;

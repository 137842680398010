import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React from 'react';

import UserActionsMenu from '@/components/common/MegaNav/UserActionsMenu/UserActionsMenu';
import SearchBar from '@/components/Search/SearchBar';
import { useStore } from '@/models/root-store';
import { AUTH_NAVIGATION_TARGET_KEEP_PATHNAMES } from '@/shared/constant';
import { CHATBOT_VERSION, SearchState } from '@/utils/constants';
import { hideSearch } from '@/utils/helpers';

interface UserActionsProps {
  setBlur: (isBlur: boolean) => void;
}
const UserActions: React.FC<UserActionsProps> = observer(({ setBlur }) => {
  const router = useRouter();
  const {
    cartStore,
    generalStore: { ready, setChatOpen },
    productStore: {
      search: { searchState },
    },
  } = useStore();
  const { cart } = cartStore;
  const { openCart } = cart;
  const authNavTarget = React.useMemo(() => {
    const keepPath = AUTH_NAVIGATION_TARGET_KEEP_PATHNAMES.some((path) =>
      router.pathname.includes(path),
    );
    return keepPath ? window.location.pathname : '/account';
  }, [router.pathname]);

  const openMSChat = () => {
    setChatOpen(true);
  };

  const openZendeskChat = () => {
    if ((window as any).zE) {
      (window as any).zE('messenger', 'open');
    }
  };

  const openChat = () => {
    switch (CHATBOT_VERSION) {
      case 'microsoft':
        return openMSChat();
      case 'zendesk':
      default:
        return openZendeskChat();
    }
  };

  const hideMenu = searchState !== SearchState.NULL;
  const searchHidden = hideSearch(router.asPath);
  return (
    <div className="flex-1 text-sm text-white lg:flex-none">
      <ul
        className={`flex ${hideMenu ? 'justify-center md:justify-end ' : 'justify-end '}items-center justify-end lg:justify-between`}
      >
        <UserActionsMenu
          openChat={openChat}
          authNavTarget={authNavTarget}
          openCart={openCart}
          hideMenu={hideMenu}
        />
        {ready && !searchHidden && (
          <li className="mx-1 self-center lg:mx-2 mobile-375:mr-0">
            <SearchBar setBlur={setBlur} />
          </li>
        )}
      </ul>
    </div>
  );
});
export default UserActions;

import React, { useEffect, useState } from 'react';

import type {
  MenuHierarchyRecordModelType,
  NavbarLinkExtraRecordModelType,
  NavbarLinkRecordModelType,
} from '@/models/api';

import DesktopCategories from '../DesktopCategories/DesktopCategories';
import DiscoveryBanner from '../DiscoveryBanner/DiscoveryBanner';
import ExtraLink from '../ExtraLink/ExtraLink';

interface CategoriesContainerProps {
  navLink: NavbarLinkRecordModelType;
  categories: Array<MenuHierarchyRecordModelType>;
  isLoggedIn: boolean;
}

const CategoriesContainer = ({
  navLink,
  categories,
  isLoggedIn,
}: CategoriesContainerProps) => {
  const [mainCategory, setMainCategory] = useState<
    MenuHierarchyRecordModelType | undefined
  >(undefined);
  const [subCategory, setSubCategory] = useState<
    MenuHierarchyRecordModelType | undefined
  >(undefined);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);

  useEffect(() => {
    setSubSubCategories([]);
  }, [mainCategory]);

  return (
    <>
      <div className="flex pb-4">
        <div className="flex flex-col">
          {navLink?.extraLinks?.map(
            (extraLink: NavbarLinkExtraRecordModelType) => (
              <ExtraLink
                key={extraLink.text}
                extraLink={extraLink}
                isLoggedIn={isLoggedIn}
                isDesktop={true}
              />
            )
          )}
        </div>

        <div className="pl-2">
          <div className="px-6 pb-2 font-interSemibold text-grey-dark">
            <span className="text-sm">Shop by Category</span>
          </div>
          <DesktopCategories
            level={1}
            categories={categories}
            setSubCategories={setSubCategories}
            setParentCategory={setMainCategory}
            noBorder={true}
          />
        </div>

        <div className="mt-8">
          <DesktopCategories
            level={2}
            categories={subCategories}
            setSubCategories={setSubSubCategories}
            parentCategory={mainCategory}
            setParentCategory={setSubCategory}
            subCategory={subCategory}
          />
        </div>

        <div className="mt-8">
          <DesktopCategories
            level={3}
            categories={subSubCategories}
            setSubCategories={() => {}}
            mainCategory={mainCategory}
            parentCategory={subCategory}
            setParentCategory={() => {}}
          />
        </div>
      </div>
      <DiscoveryBanner banner={navLink.discoveryBanner} />
    </>
  );
};

export default CategoriesContainer;

import Link from 'next/link';
import React from 'react';

import type {
  MenuHierarchyRecordModelType,
  NavbarLinkExtraRecordModelType,
  NavbarLinkRecordModelType,
} from '@/models/api';

import CategoriesContainer from '../CategoriesContainer/CategoriesContainer';
import ExploreLink from '../ExploreLink/ExploreLink';

export const dynamic = 'force-dynamic';

interface SubLinksContainerProps {
  navLink: NavbarLinkRecordModelType;
  categories: Array<MenuHierarchyRecordModelType>;
  isLoggedIn: boolean;
}

const SubLinksContainer = ({
  navLink,
  categories,
  isLoggedIn,
}: SubLinksContainerProps) => {
  const { subLinks, extraLinks } = navLink;

  return (
    <div className="nav-links-container absolute left-0 top-[71px] z-20 m-0 w-full origin-top scale-0 flex-col overflow-scroll bg-white p-4 font-interMedium shadow transition lg:flex">
      <div className="container mt-4 pb-4">
        {navLink.fullMenu ? (
          <CategoriesContainer
            navLink={navLink}
            categories={categories}
            isLoggedIn={isLoggedIn}
          />
        ) : (
          <ul
            className={`relative flex w-auto items-start justify-center leading-4`}
          >
            {subLinks?.map((data: NavbarLinkRecordModelType) => (
              <li key={data.accessibilityText} className="px-12">
                <Link
                  href={data.relativePathLink || data.externalUrl || '/'}
                  target={data.externalUrl ? '_blank' : '_self'}
                >
                  <div className="text-base text-black transition hover:text-red-600 hover:underline">
                    {data.accessibilityText}
                  </div>
                </Link>
              </li>
            ))}
            {extraLinks?.map((data: NavbarLinkExtraRecordModelType) => (
              <li key={data.text} className="px-4">
                <ExploreLink
                  key={data.text}
                  extraLink={data}
                  isLoggedIn={isLoggedIn}
                  isDesktop
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SubLinksContainer;

import { OtherProductTile } from '@/components/common/OtherProductTile/OtherProductTile';
import type { ProductModelType } from '@/models/api';

export type OtherProductsType = {
  products: ProductModelType[];
  title: string;
  refreshUpSells: () => void;
};

export const OtherProductsContainer: React.FC<OtherProductsType> = ({
  products,
  title,
  refreshUpSells,
}) => {
  return (
    <div className="w-full max-w-full">
      <div className="mb-4 flex justify-between pl-6 pr-2 md:pr-4">
        <div className="my-3 font-interSemibold text-2xl">{title}</div>
      </div>

      {products.map((product: ProductModelType) => (
        <div key={product._id} className="w-full">
          <OtherProductTile product={product} refreshUpSells={refreshUpSells} />
        </div>
      ))}
    </div>
  );
};

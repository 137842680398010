import 'react-sliding-pane/dist/react-sliding-pane.css';

import _ from 'lodash';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import SlidingPane from 'react-sliding-pane';

import type { ButtonProps } from '@/components/common/Button/Button';
import Button from '@/components/common/Button/Button';
import { Card } from '@/components/common/Card/Card';
import CloseIcon from '@/components/icons/CloseIcon';
import { TILE_SELECTOR } from '@/graphql/selectors/goalBased';
import type { ActionCardRecordModelType } from '@/models/api';
import { useQuery } from '@/models/reactUtils';
import { useStore } from '@/models/root-store';
import { Mode } from '@/utils/constants';

type SelectPlansProps = {
  isOpen?: boolean;
  closeMealPlan: () => void;
  closeCart: () => void;
  mode?: Mode;
  showBottom?: boolean;
};

const SelectPlans: React.FC<SelectPlansProps> = ({
  isOpen = false,
  closeMealPlan,
  closeCart,
  mode,
  showBottom = true,
}) => {
  const store = useStore();
  const {
    cartStore: { cart, draftCart },
    generalStore: { MOV, SMOV },
  } = store;
  const MinOrderValue = draftCart.mode === Mode.EDIT_SUBSCRIPTION ? SMOV : MOV;

  const { subTotal } = cart;
  const router = useRouter();

  const styles = {
    closeIconStyle: {
      width: '24px !important',
    },
  };

  const { data } = useQuery((queryStore) =>
    queryStore.api.querySiteContentCms(
      {
        filter: {
          key: { eq: 'cart-select-plan' },
        },
      },
      TILE_SELECTOR
    )
  );
  const plans: ActionCardRecordModelType[] = useMemo(() => {
    return _.get(data, 'siteContentCms.blocks', []);
  }, [data]);

  const createPlan = (planRoute: string) => {
    closeMealPlan();
    closeCart();
    router.push({
      pathname: planRoute,
      query: { ...(mode && { mode }) },
    });
  };

  return (
    <SlidingPane
      isOpen={isOpen}
      title={<div className="font-interMedium text-lg">Select a Plan</div>}
      className="!w-full md:max-w-[392px]"
      from="right"
      closeIcon={
        <div style={styles.closeIconStyle}>
          <CloseIcon />
        </div>
      }
      onRequestClose={closeMealPlan}
    >
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col px-6">
          {plans &&
            plans.length > 0 &&
            plans.map((plan, index: number) => (
              <div key={index}>
                <Card
                  imageSrc={plan?.image?.url}
                  title={
                    <span className="font-interMedium text-2xl">
                      {plan.title}
                    </span>
                  }
                  description={plan.description || ''}
                  background={
                    plan.backgroundColor
                      ? `bg-${plan.backgroundColor}`
                      : 'bg-blue'
                  }
                >
                  <Button
                    theme={
                      (plan.buttonTheme || 'tertiary') as ButtonProps['theme']
                    }
                    onClick={() => createPlan(plan.actionPath || '')}
                  >
                    {plan.buttonText}
                  </Button>
                </Card>
              </div>
            ))}
        </div>
        {showBottom && (
          <div className="bottom-0 right-0 border-t border-grey-lite bg-white p-6">
            <div className="text-xl font-bold leading-7">
              ${subTotal.toFixed(2)}
            </div>
            <div className="text-xs font-medium text-chef-bold">
              {!(subTotal >= MinOrderValue) ? (
                <span>Min. order value ${MinOrderValue}</span>
              ) : (
                <span>
                  Shipping calculated<br></br>at checkout
                </span>
              )}
              ƒ
            </div>
          </div>
        )}
      </div>
    </SlidingPane>
  );
};

export default SelectPlans;

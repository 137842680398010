import React from 'react';

import Button from '@/components/common/Button/Button';
import { Modal } from '@/components/common/Modal/Modal';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const MoreThanOneWLPModal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
}: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      headerTitle={''}
      customContentStyles={{ borderRadius: '16px' }}
    >
      <div className="mx-auto w-auto max-w-sm lg:max-w-xl">
        <div className="flex w-full flex-col bg-white">
          <h2 className="mx-auto mb-8 max-w-sm pt-4 font-interMedium text-xl text-black md:text-3xl md:leading-[30px]">
            Oops!
          </h2>
          <div className="px-3 pt-4 text-center font-interMedium text-lg leading-7 text-black lg:leading-10">
            Want to checkout with your Weight Loss Plan? <br />
            You can&apos;t proceed unless you remove the other WLP plans in your
            cart. <br />
          </div>
          <div className="flex flex-col items-center justify-end px-6 pt-8 pb-6 lg:pt-12">
            <Button theme="mealPlan" type="button" onClick={closeModal}>
              <span className="px-6 py-1 font-interMedium text-base md:text-xl">
                Okay
              </span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

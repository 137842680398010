import React from 'react';

const UpwardArrow = (props: React.SVGProps<SVGSVGElement>) => {
  const { color = 'black' } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4888_7845"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
        type="alpha"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4888_7845)">
        <path
          d="M4.26683 12.0002L3.3335 11.0668L9.7335 4.66683H4.00016V3.3335H12.0002V11.3335H10.6668V5.60016L4.26683 12.0002Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
export default UpwardArrow;

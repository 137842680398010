import Image from 'next/image';
import React, { useState } from 'react';

import MealPlanItems from '@/components/cart/MealPlanItems/MealPlanItems';
import type { OrderPlan } from '@/models/cart/order-plan';

export type GoalBasedPlanTypes = {
  plan: OrderPlan;
  handleEditPlan: (planId: string) => void;
  handleRemovePlan: (planId: string) => void;
};

const GoalBasedPlan: React.FC<GoalBasedPlanTypes> = ({
  plan,
  handleEditPlan,
  handleRemovePlan,
}: GoalBasedPlanTypes) => {
  const [showMealPlanItems, setShowMealPlanItems] = useState(false);
  const toggleShowMealPlanItems = () => {
    setShowMealPlanItems(!showMealPlanItems);
  };

  const { name, total, image } = plan;
  const planName = name;
  const { numberOfItems } = plan;
  const numberOfDays = plan?.attributes?.find(
    (attribute: any) => attribute.name === 'numberOfDays'
  )?.value;

  const getDay = (item: any) => {
    return item.attributes.find((attribute: any) => attribute.name === 'day')
      ?.value;
  };
  const lineItems = [];
  for (let i = 1; i <= numberOfDays; i += 1) {
    const items = plan.lineItems.filter((item: any) => getDay(item) === i);
    lineItems.push(<MealPlanItems key={i} day={i} lineItems={items} />);
  }

  return (
    <div>
      <div className="flex w-full items-center justify-between px-6 pb-7">
        <div className="flex items-center">
          <h2 className="mr-3 font-interSemibold text-2xl">Meal Plan</h2>
          <span className="flex h-7 w-7 items-center justify-center rounded-full border border-grey-dark font-interMedium text-base leading-none text-grey-dark">
            1
          </span>
        </div>
      </div>

      <div className="relative block px-6">
        <div className=" mb-4 flex w-full flex-col">
          <div className="flex">
            {image && (
              <div className="flex shrink-0">
                <Image
                  src={image}
                  alt="Cart Basket"
                  width={104}
                  height={104}
                  className="h-[104px] rounded-lg !object-cover"
                />
              </div>
            )}
            <div className="flex w-full flex-col justify-between pl-3">
              <h5 className="max-w-[167px] truncate pb-2.5 font-interMedium text-sm leading-snug">
                {planName}
                <span
                  className="block cursor-pointer font-interMedium text-sm text-attention"
                  onClick={toggleShowMealPlanItems}
                >
                  ({numberOfItems} {numberOfItems >= 1 ? 'items' : 'item'})
                </span>
              </h5>
              <div className="flex justify-between">
                <button
                  className="w-fit font-interMedium text-sm leading-none text-blueberry"
                  onClick={() => handleEditPlan(plan.planId)}
                >
                  Edit Items
                </button>
                <p className="font-interSemibold leading-4">
                  ${total.toFixed(2)}
                </p>
              </div>
            </div>
            <div className="absolute right-2 top-2 cursor-pointer">
              <Image
                src="/assets/images/svg/delete.svg"
                alt="Delete Cart"
                width={20}
                height={15}
                onClick={() => handleRemovePlan(plan.planId)}
                className="object-none"
              />
            </div>
          </div>

          <div
            className={`relative px-3 pt-4 ${
              showMealPlanItems ? 'block' : 'hidden'
            }`}
          >
            <div className="absolute top-3 right-3 cursor-pointer">
              <Image
                src="/assets/images/svg/close.svg"
                alt="close"
                width={10.6}
                height={10.6}
                onClick={toggleShowMealPlanItems}
              />
            </div>
          </div>

          {showMealPlanItems && <div className="px-5">{lineItems}</div>}
        </div>
      </div>
      <hr className="my-4" />
    </div>
  );
};

export default GoalBasedPlan;

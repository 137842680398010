import Link from 'next/link';
import React from 'react';

import type {
  NavbarLinkRecordModelType,
  NavbarRecordModelType,
} from '@/models/api';

import MobileNavLink from '../MobileNavLink/MobileNavLink';

interface MobileNavLinksProps {
  navLinks: NavbarRecordModelType | undefined;
  selectedLink: NavbarLinkRecordModelType | undefined;
  setSelectedLink: (link: NavbarLinkRecordModelType) => void;
  onClick: () => void;
}

const MobileNavLinks = ({
  navLinks,
  selectedLink,
  setSelectedLink,
  onClick,
}: MobileNavLinksProps) => {
  return (
    <>
      {/* Primary Links */}
      {!selectedLink && (
        <ul className="flex flex-col font-interSemibold text-xl">
          {navLinks?.links?.map((navLink, index) => {
            return (
              <div key={index}>
                {navLink.linkType === 'Primary' &&
                  navLink.category === 'mealPlan' && (
                    <MobileNavLink
                      key={navLink.text}
                      navLink={navLink}
                      setSelectedLink={setSelectedLink}
                      onClick={onClick}
                    />
                  )}
              </div>
            );
          })}
        </ul>
      )}

      {/* Sub Links */}
      {selectedLink && !!selectedLink?.subLinks?.length && (
        <ul className="mb-8 flex flex-col font-interMedium text-lg">
          <Link
            href={
              selectedLink.relativePathLink || selectedLink.externalUrl || '/'
            }
          >
            <li className="h-[37px] font-interBold leading-7" onClick={onClick}>
              View all {selectedLink.text}
            </li>
          </Link>
          {selectedLink?.subLinks?.map((navLink: NavbarLinkRecordModelType) => {
            return (
              <MobileNavLink
                key={navLink.text}
                navLink={navLink}
                onClick={onClick}
                selectedLink={selectedLink}
              />
            );
          })}
        </ul>
      )}
    </>
  );
};

export default MobileNavLinks;

import Link from 'next/link';
import React from 'react';

import ArrowDown from '@/components/icons/ArrowDown';
import { useMediaQuery } from '@/hooks';

import NavLink from '../NavLink/NavLink';

interface AdditionalLinksTypes {
  id: string;
  accessibilityText: string;
  externalLink: string;
  internalLink: string;
  text: string;
}

const DropdownLink = ({ item }: any) => {
  const [dropdown, setDropdown] = React.useState(false);

  const breakLine = React.useMemo(() => {
    if (!item.sectionLinks) {
      return item?.text?.split('\n')?.map((text: string, index: string) => (
        <li className="pb-[9.6px] leading-none" key={index}>
          {text}
        </li>
      ));
    }
    return null;
  }, [item]);

  const maxHeight =
    useMediaQuery(`(max-width:1024px)`) && dropdown ? '400px' : '0';

  return (
    <React.Fragment>
      <div
        className="mb-4 flex justify-between border-b border-grey-dark pb-1 font-interMedium text-base leading-5 lg:mb-6 lg:border-0 lg:pb-0"
        onClick={() => setDropdown(!dropdown)}
      >
        {item.sectionName === 'Contact Us' ? (
          <h5 className="lg:mt-12">{item.sectionName}</h5>
        ) : (
          <h5>{item.sectionName}</h5>
        )}
        <span className="flex lg:hidden">
          <ArrowDown
            fill="#222"
            style={{
              transition: 'all 200ms ease',
              transform: `${dropdown ? 'rotate(180deg)' : 'rotate(0deg)'}`,
            }}
          />
        </span>
      </div>
      <ul
        style={{
          maxHeight,
          transition: 'all 400ms ease-in',
        }}
        className={`max-h-0 overflow-y-hidden text-xs text-gray-900 lg:!max-h-full lg:!overflow-y-visible`}
      >
        {item.sectionLinks && item.sectionLinks.length > 0 ? (
          <NavLink key={item.sectionName} sectionData={item} />
        ) : (
          breakLine
        )}
        {item?.additionalLinks && (
          <hr className="mb-4 mt-1.5 h-px w-[102px] border-black" />
        )}
        {item?.additionalLinks &&
          item?.additionalLinks.map((t: AdditionalLinksTypes, i: number) => {
            return (
              <li
                className="flex items-center pb-[9.6px] leading-none last:pb-8 hover:text-[#d8173c] hover:underline lg:last:pb-0"
                key={i}
              >
                {t.internalLink ? (
                  <Link href={t.internalLink} legacyBehavior>
                    {t.text}
                  </Link>
                ) : (
                  <a target="_blank" href={t.externalLink} rel="noreferrer">
                    {t.text}
                  </a>
                )}
              </li>
            );
          })}
      </ul>
    </React.Fragment>
  );
};

export default DropdownLink;

import type { ReactNode } from 'react';
import { useState } from 'react';

import Footer from '@/components/common/Footer/Footer/Footer';
import MegaNav from '@/components/common/MegaNav/MegaNav';
import SubscriptionBar from '@/components/common/Nav/SubscriptionBar/SubscriptionBar';
import SeoBlurb from '@/components/common/SeoBlurb/SeoBlurb';

type IMainProps = {
  meta: ReactNode;
  children: ReactNode;
  seoBlurb?: string;
};

function Main(props: IMainProps) {
  const [menuBar, setMenuBar] = useState(false);
  const [isBlur, setIsBlur] = useState(false);

  return (
    <div className="bg-gray-100">
      <div
        className={`${
          menuBar || isBlur
            ? 'slide-pane__overlay overlay-after-open top-0 z-50'
            : ''
        }`}
      />
      <div
        className={`relative m-auto w-full bg-white antialiased ${
          menuBar && 'fixed h-full'
        }`}
      >
        {props.meta}
        <MegaNav
          menuBar={menuBar}
          setMenuBar={setMenuBar}
          setIsBlur={setIsBlur}
        />
        <SubscriptionBar />
        <div className="w-full">{props.children}</div>
        {props.seoBlurb && <SeoBlurb blurb={props.seoBlurb} />}
        <Footer />
      </div>
    </div>
  );
}

export { Main };

import React from 'react';

import Button from '@/components/common/Button/Button';
import { Modal } from '@/components/common/Modal/Modal';

type SuccessModalProps = {
  isSuccess?: boolean;
  isSuccessClose: () => void;
  title?: string;
  subTitle?: string;
  description?: string;
};

const SuccessModal: React.FC<SuccessModalProps> = ({
  isSuccess = false,
  isSuccessClose,
  subTitle,
  description = 'Please note this applies to your future orders. Orders already processed will not be updated.',
  title,
}) => {
  return (
    <Modal
      isOpen={isSuccess}
      onRequestClose={() => isSuccessClose()}
      customPadding="p-4"
    >
      <div className="relative my-0 py-0">
        <div className="custom-scroll text-center font-inter lg:px-8 lg:py-6 ">
          <h2 className="mx-auto mb-8 max-w-sm pt-4 font-interMedium text-xl text-black md:text-3xl md:leading-[30px]">
            {title}
          </h2>
          <div className="mb-10 grid gap-8 text-base font-semibold md:text-lg md:leading-[22px]">
            {subTitle && <p>{subTitle}</p>}
            {description && <p>{description}</p>}
          </div>
          <div className="flex items-center justify-center">
            <Button
              className="h-10 w-20 rounded-32 border border-black py-2 text-center font-semibold text-black md:w-56"
              theme="tertiary"
              onClick={() => isSuccessClose()}
            >
              Okay
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
